import { VerifyEmailComponent } from './routes/verify-email/verify-email.component';
import { LayoutComponent as ScreenLayoutComponent } from './routes/screen/layout/layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './routes/login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { AuthGuard } from './services/auth.guard';
import { InitResolver } from 'src/app/resolvers/init.resolver';
import { ForgotPasswordComponent } from './routes/users/forgot-password/forgot-password.component';
import { PasswordResetComponent } from './routes/users/password-reset/password-reset.component';
import { ChatFullScreentComponent } from './routes/chat-full-screent/chat-full-screent.component';
import { PatientResolver } from './resolvers/patient.resolver';
const routes: Routes = [
  {
    path: 'login', component: LoginComponent,
    data: {
      title: 'Login',
    },
  },
  {
    path: 'forgot-password', component: ForgotPasswordComponent,
    data: {
      title: 'Forgot password',
    },
  },
  {
    path: 'activate/:token', component: VerifyEmailComponent,
  },
  {
    path: 'users/password-reset/:token',
    loadChildren: () => import('./routes/users/users.module').then((m) => m.UsersModule),
    component: PasswordResetComponent,
  },
  {
    path: 'maps',
    canActivate: [AuthGuard],
    loadChildren: () => import('./routes/google-maps/google-maps.module').then((m) => m.GoogleMapsModule),
  },
  {
    path: 'chat',
    canActivate: [AuthGuard],
    component: ChatFullScreentComponent,
  },
  {
    path: 'screen',
    component: ScreenLayoutComponent,
    children: [
      {
        path: 'nurses-login',
        loadChildren: () => import('./routes/screen/screen.module').then((m) => m.ScreenModule),
      }
    ]
  },
  {
    path: 'screen',
    component: ScreenLayoutComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/screen/screen.module').then((m) => m.ScreenModule),
      }
    ]
  },
  {
    path: '', component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'translations',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/translations/translations.module').then((m) => m.TranslationsModule),
      },
      {
        path: 'medical-centers',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/medical-centers/medical-centers.module').then((m) => m.MedicalCentersModule),
      },
      {
        path: 'patients',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/patients/patients.module').then((m) => m.PatientsModule),
        resolve: {
          data: PatientResolver,
        }
      },
      {
        path: 'report/:id',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'devices',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/devices/devices.module').then((m) => m.DevicesModule),
      },
      {
        path: 'medical-staff',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/medical-staff/medical-staff.module').then((m) => m.MedicalStaffModule),
      },
      {
        path: 'sims',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/sims/sims.module').then((m) => m.SimsModule),
      },
      {
        path: 'phone-application-version',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/phone-application-version/phone-application-version.module').then((m) => m.PhoneApplicationVersionModule),
      },
      {
        path: 'patients/overview/:id',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/patients/overview/overview.module').then((m) => m.OverviewModule),
      },
      {
        path: 'audit-log',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/audit-log/audit-log.module').then((m) => m.AuditLogModule),
      },
      {
        path: 'schedule',
        canActivate: [AuthGuard],
        loadChildren: () => import('./components/appointments/app.module').then((m) => m.AppModule),
      },
      {
        path: 'questions',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/questions/questions.module').then((m) => m.QuestionsModule),
      },
      {
        path: 'questionnaires',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/questionaries/questionaries.module').then((m) => m.QuestionariesModule),
      },
      {
        path: 'garmin-app',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/garmin-app/garmin-app.module').then((m) => m.GarminAppModule),
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        resolve: {
          init: InitResolver,
        }
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
