import { AndroidAppInterface } from './android-app-interface';
type Message = { action: string; payload: any };
export class ReactNativeCommunicator implements AndroidAppInterface {
  private reactNative: any;

  constructor() {
    this.reactNative = (window as any).ReactNativeWebView;

    window.addEventListener('message', (nativeEvent) => {
      console.log(nativeEvent?.data);
    });
  }

  sendMessageToRN(message: Message) {
    this.reactNative.postMessage(JSON.stringify(message));
  }

  sendLoginData(userID: number, onDuty: number) {
    this.sendMessageToRN({
      action: 'loginData',
      payload: {
        userID,
        onDuty
      }
    });
  }
  
  getFirebaseToken() {
    this.sendMessageToRN({
      action: 'getFirebaseToken',
      payload: {}
    });
  }
}
