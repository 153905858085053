import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertSettingsComponent } from './alert-settings.component';
@NgModule({
  declarations: [
    AlertSettingsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    AlertSettingsComponent
  ],
})

export class AlertSettingsModule { }