import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class MedicalCodeService {

    static readonly GARMIN_MEDICAL_CODE = '01405';
    private medicalCodes = [];
    constructor(private api$: ApiService, private localStorage: LocalStorageService) {
    }

    public getMedicalCodes() {
      this.medicalCodes = this.localStorage.retrieve('medicalCodes');
      if (!this.medicalCodes) {
        return this.api$.getMedicalCodes().subscribe(data => {
          if (data.success == true) {
            this.medicalCodes = data.data;
            this.localStorage.store('medicalCodes', data.data);
            return data.data;
          }

        });
      } else {
        return;
      }
    }

    findDataByMedicalCode(data, medicalCode) {

      return data.filter(x => x.MedicalCodeMainID === medicalCode);
    }


    public findMedicalCodesByParent(MedicalCodeID: string) {
      this.medicalCodes = this.localStorage.retrieve('medicalCodes');
      if (this.medicalCodes.length == 0) {
        throw new Error('Empty MedicalCodes');
      }
      return this.medicalCodes.filter(x => x.MedicalCodeMainID === MedicalCodeID);
    }

    public findDataByMedicalCodeID(MedicalCodeID: string) {
      this.medicalCodes = this.localStorage.retrieve('medicalCodes');
      if (this.medicalCodes.length == 0) {
        throw new Error('Empty Medical Codes');
      }
      return this.medicalCodes.find(x => x.MedicalCodeID === MedicalCodeID);
    }

    public getObservTypes() {
      return this.findMedicalCodesByParent('00800');
    }

    public getObservReasons() {
      return this.findMedicalCodesByParent('00600');

    }
    public getObservPeriods() {
      return this.findMedicalCodesByParent('00700');
    }

    public getWorkingModes() {
      return this.findMedicalCodesByParent('00900');
    }

    public getBloodTypes() {
      return this.findMedicalCodesByParent('01000');
    }

    public getMedicalStaffTypes() {
      return this.findMedicalCodesByParent('00400');
    }

    public getMedicalStaffAcademicTitles() {
      return this.findMedicalCodesByParent('00200');
    }

    public getMedicalStaffExperiences() {
      return this.findMedicalCodesByParent('00300');
    }

    public getSpoSensorTypes() {
      return this.findMedicalCodesByParent('01200');
    }

    public getDeviceModels() {
      return this.findMedicalCodesByParent('01400');
    }

    public getSimStatuses() {
      return this.findMedicalCodesByParent('01500');
    }

    public getDeviceStatuses() {
      return this.findMedicalCodesByParent('01100');
    }
    public getOptions() {
      return this.findMedicalCodesByParent('01100');
    }
}
