import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class PatientResolver implements Resolve<any> {

  constructor(
    private api$: ApiService,
    private localStorage: LocalStorageService
  ) {
  }
  resolve(): Observable<any> | Promise<any> | any {
    return this.api$.getPurposes().pipe(
      map(data => {  
        if (data.success === true) {
          this.localStorage.store('purposes', data.data);
        }
        return data.data;

      }),
      catchError(error => {
        return Observable.throwError('error', error);
      })
    ); // end of pi
  }

}

