import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { ApiService } from '../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { HeadingService } from '../../services/heading.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    @ViewChild('recaptchaRef')
    recaptchaRef: RecaptchaComponent;
    needTokenConfirmation = false;
    loginForm: FormGroup;
    confirmationForm: FormGroup;
    submitted = false;
    languages = [];
    useRecaptcha = true;
    confirmationCodeError = false;
    sendingLoginRequest = false;

    @LocalStorage() currentLang: string;
    constructor(private formBuilder: FormBuilder,
        private router: Router,
        private heading$: HeadingService,
        private localStorage: LocalStorageService,
        private translate$: TranslateService,
        private api$: ApiService,
        private modalService: NgbModal) {
      this.useRecaptcha = environment.useRecaptcha;
      this.heading$.title.next({ text: 'Checkpoint Cardio' });
      if (!this.currentLang) {
        this.currentLang = this.translate$.currentLang;
      } else {
        if (this.currentLang != this.translate$.currentLang) {

        }
      }
    }

    ngOnInit() {
      this.loginForm = this.formBuilder.group({
        username: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        language: [this.currentLang],
        recaptcha: ['', this.useRecaptcha ? Validators.required : null],
      });
      this.confirmationForm = this.formBuilder.group({
        confirmationCode: ['', [Validators.required]]
      });
      this.getLanguages();
    }

    getLanguages(): void {
      this.api$.getLanguages()
        .subscribe(data => {
          this.languages = data.data;
        });
    }

    // convenience getter for easy access to form fields
    get f() {
      return this.loginForm.controls;
    }

    onSubmit(content) {
      if (this.sendingLoginRequest == false) {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
          return;
        }
        this.getLoginAut(content);
      }

    }

    changeLanguage(newLang) {
      this.translate$.use(newLang);
      this.translate$.setDefaultLang(newLang);
      if (newLang != this.currentLang) {
        this.localStorage.clear();
        this.currentLang = newLang;
      }
    }

    changeLanguageFix(newLang) {
      this.translate$.use(newLang);
      this.translate$.setDefaultLang(newLang);
      if (newLang != this.currentLang) {
        this.currentLang = newLang;
      }
    }



    // check if username and password are from database
    getLoginAut(content): void {
      this.sendingLoginRequest = true;
      this.confirmationCodeError = false;
      this.api$.getLoginAut(this.loginForm.value.username, this.loginForm.value.password, this.loginForm.value.recaptcha)
        .subscribe(data => {
          this.sendingLoginRequest = false;
          this.submitted = false;
          if (data.success === true) {
            this.localStorage.store('token', data.data.userToken.token);
            this.localStorage.store('loggedUser', data.data);
            if (data.data.UserBookingUid) {
              this.localStorage.store('userUid', data.data.UserBookingUid);
            }
            this.localStorage.store('selectedMedCenter', 0);
            if ([301, 165].includes(data.data.UserID)) {
              this.changeLanguageFix('sl');
            } else if ([140].includes(data.data.UserID)) {
              this.changeLanguageFix('en');
            } else if (data.data.UserLang) {
              this.changeLanguageFix(data.data.UserLang);
            }
            if (data.data.isTokenConfirmed == 1) {
              this.router.navigate(['/dashboard']).then();
            } else {
              this.needTokenConfirmation = true;
            }
          } else {
            this.open(content);
            if (this.recaptchaRef) {
              this.recaptchaRef.reset();
            }
          }
        });
    }

    // open dialog for mismatch on username and password
    open(content) {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    }

    public addTokenLog(message: string, token: string | null) {
      console.log('token', token);
    }

    public sendConfirmationCode() {
      this.confirmationCodeError = false;
      this.api$.confirmLoginToken(this.confirmationForm.value.confirmationCode)
        .subscribe(data => {
          if (data.success === true) {
            if (data.data == 1) {
              this.router.navigate(['/dashboard']).then();
            } else {
              this.router.navigate(['/login']).then();
            }
          } else if (data.data.message == 'Confirmation code has expired') {
            this.needTokenConfirmation = false;
            this.loginForm.patchValue({
              username: '',
              password: '',
            });
            this.confirmationForm.patchValue({
              confirmationCode: '',
            });
            this.confirmationCodeError = true;
          } else {
            this.confirmationCodeError = true;
          }
        });
    }
}
