import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { IcuPatientComponent } from '../routes/screen/icu-patient/icu-patient.component';

export function getYearOfDate(date: string): number {
  return +date.slice(0, 4);
}

export function getMonthOfDate(date: string): number {
  return +date.slice(5, 7);
}

export function getDayOfDate(date: string): number {
  return +date.slice(8, 10);
}

export function ngbToDbDate(date: NgbDate): string {
  return date.year + '-' + date.month + '-' + date.day;
}

export function dbToNgbDate(date: string): NgbDate | null {
  return date?.length > 9 ? new NgbDate(getYearOfDate(date), getMonthOfDate(date), getDayOfDate(date)) : null;
}

export function calculateAge(birthday) { // birthday is a date
  const today = new Date();
  const birthDate = new Date(birthday);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age = age - 1;
  }

  return age;
}

export function getColorByLevel(level: number): string {
  const colors = ['green', 'yellow', 'orange', 'red'];
  return colors[level];
}

export function getPriorityByLevel(level: number): string {
  const priority = ['', 'low priority', 'middle priority', 'high priority'];
  return priority[level];
}

export function getFollowUpActions(): any {
  return [
    { action: -2, actionName: '' },
    { action: 0, actionName: 'Continue routine observation' },
    { action: 1, actionName: 'Urgent assessment from the ward doctor' },
    { action: 2, actionName: 'Emergent assessment from the ward doctor' },
    { action: 3, actionName: 'Emergent assessment  by clinical team' },
    { action: -1, actionName: 'Reject' },
  ];
}

export function getPosition(pos, translate) {
  switch (pos) {
    case 0:
      return translate.instant('Upside down'); //562=Лежащ по очи
    case 1:
      return translate.instant('Lay'); //559=Лежащ
    case 2:
      return translate.instant('Lay left'); //563=Лежащ наляво
    case 3:
      return translate.instant('Lay right'); //564=Лежащ надясно
    case 4:
      return translate.instant('Sitting'); //553 седнал
    case 5:
      return translate.instant('Lean back'); //561=Облегнат
    case 6:
      return translate.instant('Standing'); //554=Стоящ
    case 7:
      return translate.instant('Walking'); //555=Вървящ
    case 8:
      return translate.instant('Running'); // 556=Бягащ
    case 10:
        return translate.instant('Prone'); // 556=Бягащ

    default:
      return translate.instant('undefined');
  }
}

export function getDetachedSensors(ae) {
  const detached = [];

  const I_attached = (ae & 0x1) == 0x1;
  const II_attached = (ae & 0x2) == 0x2;
  const Dorsalis_attached = (ae & 0x2) == 0x2;
  const III_attached = (ae & 0x3) == 0x3;
  const Anterior_attached = (ae & 0x7) == 0x7;
  const Inferior_attached = (ae & 0x7) == 0x7;
  const V1_attached = (ae & 0x80) == 0x80;
  const V2_attached = (ae & 0x4) == 0x4;
  const V3_attached = (ae & 0x8) == 0x8;
  const V4_attached = (ae & 0x10) == 0x10;
  const V5_attached = (ae & 0x20) == 0x20;
  const V6_attached = (ae & 0x40) == 0x40;
  const aVR_attached = (ae & 0x3) == 0x3;
  const aVL_attached = (ae & 0x3) == 0x3;
  const aVF_attached = (ae & 0x3) == 0x3;
  const SPO_attached = (ae & 0x100) == 0x100;
  if (!I_attached) {
    detached.push('I');
  }
  if (!II_attached) {
    detached.push('II');
  }
  if (!Dorsalis_attached) {
    detached.push('Dorsalis');
  }
  if (!III_attached) {
    detached.push('III');
  }
  if (!Anterior_attached) {
    detached.push('Anterior');
  }
  if (!Inferior_attached) {
    detached.push('Inferior');
  }
  if (!V1_attached) {
    detached.push('V1');
  }
  if (!V2_attached) {
    detached.push('V2');
  }
  if (!V3_attached) {
    detached.push('V3');
  }
  if (!V4_attached) {
    detached.push('V4');
  }
  if (!V5_attached) {
    detached.push('V5');
  }
  if (!V6_attached) {
    detached.push('V6');
  }
  if (!aVR_attached) {
    detached.push('aVR');
  }
  if (!aVL_attached) {
    detached.push('aVL');
  }
  if (!aVF_attached) {
    detached.push('aVF');
  }
  if (!SPO_attached) {
    detached.push('SPO');
  }
  return detached;
}

export function mapPosition(pos) {
  const map = {
    1: 4,//553 седнал
    2: 6,//554=Стоящ
    3: 7,//555=Вървящ
    4: 8,// 556=Бягащ
    6: 8,//557=Шофиращ
    7: 1,//559=Лежащ
    8: 2,//563=Лежащ наляво
    9: 3,//564=Лежащ надясно
    10: 0,//562=Лежащ по очи
    11: 5 //561=Облегнат
  };
  return map.hasOwnProperty(pos) ? map[pos]: null;
}

export function mapActivity(pos) {
  const map = {
    1: 'low',//553 седнал
    2: 'medium',//554=Стоящ
    3: 'high',//555=Вървящ
    4: 'high',// 556=Бягащ
    6: 'high',//557=Шофиращ
    7: 'low',//559=Лежащ
    8: 'low',//563=Лежащ наляво
    9: 'low',//564=Лежащ надясно
    10: 'low',//562=Лежащ по очи
    11: 'medium' //561=Облегнат
  };
  return map.hasOwnProperty(pos) ? map[pos]: 'undefined';
}

export function playSound(level: number) {
  const audio = new Audio();
  switch (level) {
  case 2:
    audio.src = '/assets/sounds/beep-08.mp3';
    audio.load();
    audio.play();
    break;
  case 3:
  default:
    audio.src = '/assets/sounds/beep-09.mp3';
    audio.load();
    audio.play();
    break;
  }
}

export function getUniqueID() {
  return Math.random().toString(36).substr(2, 9);
}
export function formatDateTimeForApi(date) {
  return (date == null) ? null : new Date(date).toISOString().replace('T', ' ').substr(0, 19);
}
export function formatTimeForApi(date) {
  return (date == null) ? null : new Date(date).toTimeString().substr(0, 5);
}
export function formatDateForApi(date) {
  const dt = new Date(date);
  return (date == null) ? null : `${dt.getFullYear()}-${('0' + (dt.getMonth() + 1)).slice(-2)}-${('0' + dt.getDate()).slice(-2)}`;
}
export function calculateBMI(weight: any, height: any) {
  return (weight / (Math.pow((height / 100), 2))).toFixed(2);
}
export function calculateBSI(weight: any, height: any) {
  return (Math.sqrt(height * weight / 3600)).toFixed(2);
}
export function isNumberKey(evt, control) {
  const charCode = (evt.which) ? evt.which : evt.keyCode;
  //access "+" at start 
  if (charCode == 43 && this.form.value.basic_info[control].length == 0) {
    return true;
  }
  if (charCode != 46 && charCode > 32
        && (charCode < 48 || charCode > 57))
    return false;

  return true;
}

export function getVal(value: any, decimalPlaces: number) {
  if (value === null) return 'N/A';
  return +parseFloat('' + value).toFixed(decimalPlaces);
}

export function splitRRule(rRuleString: string) {
  //FREQ=WEEKLY;COUNT=5;INTERVAL=1;WKST=MO;BYDAY=MO,WE,TH,FR
  const data = [];
  (rRuleString.split(';')).forEach(element => {
    const elData = element.split('=');
    data[elData[0]] = elData[1];
  });
  return data;
}

export function getEventTime(string) {
  console.log('getEventTime', string);
  const time = string.split(':');
  var now = new Date();
  now.setHours(time[0]);
  now.setMinutes(time[1]);
  now.setMilliseconds(0);
  console.log('getEventTime', now);
  return now;
}
