import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})

export class RightsService {

  constructor(private localStorage: LocalStorageService) { }

  can(rights) {
    const loggedUser = this.localStorage.retrieve('loggedUser');
    if (loggedUser.userRoles.includes('root-admin')) {
      return true;
    }
    if (!Array.isArray(rights)) {
      rights = [rights];
    }
    const rolesInters = loggedUser.userRoles.filter(element => rights.includes(element));
    if (rolesInters.length) {
      return true;
    }
    const permisInters = loggedUser.userPermissions.filter(element => rights.includes(element));
    if (permisInters.length) {
      return true;
    }
    return false;
    
  }

  canPermission(permission) {
    const loggedUser = this.localStorage.retrieve('loggedUser');
    const permisInters = loggedUser.userPermissions.filter(element => permission.includes(element));
    if (permisInters.length) {
      return true;
    }
    return false;
    
  }
}
