import { ApiService } from 'src/app/services/api.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class MedicalCenterService {

    public medicalCenterID: Observable<number>;
    private data: any;
    private medicalCenterIDSubject: BehaviorSubject<number>;

    constructor(
      private localStorage: LocalStorageService,
      private api$: ApiService
    ) {
      let medicalCenter = this.localStorage.retrieve('selectedMedCenter');
      if (!medicalCenter && medicalCenter !== 0) {
        medicalCenter = this.localStorage.retrieve('loggedUser')?.userMedicalCenter.MedicalCenterID;
      }
      this.medicalCenterIDSubject = new BehaviorSubject<number>(medicalCenter);
      this.medicalCenterID = this.medicalCenterIDSubject.asObservable();
    }

    public getMedicalCenters(fromCache = true): Observable<any> {
      const data = this.localStorage.retrieve('medical-centers');
      if (fromCache === false || !data) {
        return this.api$.getMedicalCentersList().map(data => {
          if (data.success == true) {
            this.data = data.data;
            this.localStorage.store('medical-centers', data.data);
            const medicalCenter = this.localStorage.retrieve('selectedMedCenter');
            if (medicalCenter > 0 ) {
              const findMedCenter = data.data.find(x => x.MedicalCenterID == medicalCenter);
              if (!findMedCenter) {
                const medicalCenter = this.localStorage.retrieve('loggedUser')?.userMedicalCenter.MedicalCenterID;
                this.setMedicalCenterID(medicalCenter);
              }
            } else if (medicalCenter == 0 && data.data.length == 1) {
              const medicalCenter = this.localStorage.retrieve('loggedUser')?.userMedicalCenter.MedicalCenterID;
              if (data.data.find(x => x.MedicalCenterID == medicalCenter)) {
                this.setMedicalCenterID(medicalCenter);
              }
            }
            return data.data;
          }

        });
      } else {
        this.data = data;
        return of(data);
      }
    }

    public getMedicalCenterID() {
      return this.medicalCenterIDSubject.getValue();
    }

    public getRequiredMedicalCenterID() {
      return this.getMedicalCenterID() > 0 ? this.getMedicalCenterID() : this.localStorage.retrieve('loggedUser')?.userMedicalCenter.MedicalCenterID;
    }

    public setMedicalCenterID(medicalCenterID: number) {

      if (!medicalCenterID) {
        medicalCenterID = 0;
      }
      this.medicalCenterIDSubject.next(medicalCenterID);
      this.localStorage.store('selectedMedCenter', medicalCenterID);
    }
    public getMedicalCenterByID(id: number) {
      if (!this.data) {
        this.data = this.localStorage.retrieve('medical-centers');
      }
      return this.data.find(x => x.MedicalCenterID === id)?.MedicalCenterName;
    }
}