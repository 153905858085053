import { HeadingService } from './../../services/heading.service';
import {Component} from '@angular/core';
import {ApiService} from '../../services/api.service';
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
    public totalpashients;
    public PatientsDataRisk;
    public PatientsDataPie;
    public totalConclucions = 'N/A';
    public totalPatientsWithHigherRiskPie;
    public totalPatientsWithlowerRiskPie;
    public medicalCenterId;
    // public photoPatientsWithHigherRisk = 'assets/images/dashboard/higherRiskImg.jpg';
    // public photoPatientsWithLowerRisk = 'assets/images/dashboard/lowerRiskImg.jpg';

    // fields for diagram
    public showXAxis = true;
    public showYAxis = true;
    public gradient = true;
    public showLegend = false;
    public showXAxisLabel = true;
    public xAxisLabel = '';
    public showYAxisLabel = true;
    public yAxisLabel = 'Patients';
    public colorScheme = {
        domain: ['#32936f', '#e83f6f', '#ffbf00 ', '#2274a5'],
    };
    public autoScale = true;

    public colorSchemeDays = {
        domain: ['#32936f', '#e83f6f', '#ffbf00 ', '#2274a5'],
    };

    // default data pie
    public dangerDataPie = [
        {
            name: 'High risk',
            value: 0,
        },
        {
            name: 'Low risk',
            value: 0,
        },
    ];

    public lowerDataPie: any[];

    public colorSchemePie = {
        domain: ['#e83f6f', '#32936f'],
    };

    // default data Days
    public freeBusyDays = [
        {
            'name': 'Busy days',
            'series': [
                {
                    'name': '2018',
                    'value': 20,
                },
            ],
        },
        {
            'name': 'Free days',
            'series': [
                {
                    'name': '2018 ',
                    'value': 25,
                },
            ],
        },
    ];


    /**
     * default Patients data
     *  */
    public patientsData = [
        {
            name: 'Patients per month',
            load: false,
            series: [
                {
                    name: 'January',
                    value: 0,
                },
                {
                    name: 'February',
                    value: 0,
                },
                {
                    name: 'March',
                    value: 0,
                },
                {
                    name: 'April',
                    value: 0,
                },
                {
                    name: 'May',
                    value: 0,
                },
                {
                    name: 'June',
                    value: 0,
                },
                {
                    name: 'July',
                    value: 0,
                },
                {
                    name: 'August',
                    value: 0,
                },
                {
                    name: 'September',
                    value: 0,
                },
                {
                    name: 'October',
                    value: 0,
                },
                {
                    name: 'November',
                    value: 0,
                },
                {
                    name: 'December',
                    value: 0,
                },
            ],
        },
    ];

    constructor(
        private api$: ApiService,
        private heading$: HeadingService,
    ) {
        this.heading$.title.next({text: 'Dashboard'});
        this.getTotalPatients();
    }


    // -----------------------------------------------------
    // ----------------------------Methods------------------
    // -----------------------------------------------------

    /**
     * get all patients from the database
     */
    getTotalPatients(): void {
        this.totalpashients = 'N/A';
        this.api$.getVisitStatistics()
            .subscribe((data) => {
                if (data.success == true) {
                    console.log('service total patients', data);
                    const visitsPerMonth = data.data.visitsPerMonth;
                    this.totalpashients = data.data.totalVisits;
                    visitsPerMonth.forEach(element => {
                        this.patientsData[0].series[element.month - 1].value = Number(element.count);
                    });
                    this.patientsData[0].load = true;
                    console.log(this.patientsData);
                }
            });
    }

    /**
     * get the Active Patients(with null "end_date" in visits table)
     * for the pies divided in low and high risk
     */
    getPatientsActiveRisk(): void {
        this.api$.getPatientsActiveRisk(this.medicalCenterId)
            .subscribe((data) => {
                console.log('service active risk PIE', data.patientsActiveRisk);
                // data.patientsActiveRisk[0] === lower risk
                // data.patientsActiveRisk[1] === higher risk
                // dangerDataPie[0] === higher risk
                // dangerDataPie[1] === lower risk

                if (data.patientsActiveRisk.length > 0) {
                    if (data.patientsActiveRisk.length === 1) {
                        console.log('data size === 1', data.patientsActiveRisk.length);
                        if (data.patientsActiveRisk[0].risk !== 'lowRisk') {
                            console.log('Low risk is < 0');
                            this.dangerDataPie[1].value = 0;
                            this.dangerDataPie[0].value = data.patientsActiveRisk[0].value;
                            this.PatientsDataPie = this.dangerDataPie;
                            this.totalPatientsWithHigherRiskPie = data.patientsActiveRisk[0].value;
                            this.totalPatientsWithlowerRiskPie = 0;
                        } else if (data.patientsActiveRisk[0].risk !== 'highRisk') {
                            console.log('High risk is < 0');
                            this.dangerDataPie[0].value = 0;
                            this.dangerDataPie[1].value = data.patientsActiveRisk[0].value;
                            this.PatientsDataPie = this.dangerDataPie;
                            this.totalPatientsWithHigherRiskPie = 0;
                            this.totalPatientsWithlowerRiskPie = data.patientsActiveRisk[0].value;
                        }
                    } else {
                        this.dangerDataPie[1].value = data.patientsActiveRisk[0].value;
                        this.dangerDataPie[0].value = data.patientsActiveRisk[1].value;
                        this.PatientsDataPie = this.dangerDataPie;
                        this.totalPatientsWithHigherRiskPie = data.patientsActiveRisk[1].value;
                        this.totalPatientsWithlowerRiskPie = data.patientsActiveRisk[0].value;
                    }
                }
            });
    }

    /**
     * get the Patients depend on start date of the visit
     * group by month for the current year
     * and divided by risk:
     * low (cmd01_cardiac_risk < 5 && cmd01_general_risk < 6),
     * high (cmd01_cardiac_risk > 5 && cmd01_general_risk > 6),
     * without (cmd01_cardiac_risk = 0 && cmd01_general_risk = 0)
     */
    getPatientsMeasureRisk(): void {
        this.api$.getPatientsMeasureRisk(this.medicalCenterId)
            .subscribe((data) => {
                for (let i = 0; i < data.patientMeasureRisk.length; i++) {
                    // let temp = data.patientMeasureRisk[i].month;

                    if (data.patientMeasureRisk[i].lowRisk !== 0) {
                        for (let a = 0; a < this.patientsData[0].series.length; a++) {
                            if (this.patientsData[0].series[a].name === data.patientMeasureRisk[i].month) {
                                this.patientsData[0].series[a].value = data.patientMeasureRisk[i].lowRisk;
                            }
                        }
                    }
                    if (data.patientMeasureRisk[i].highRisk !== 0) {
                        for (let a = 0; a < this.patientsData[1].series.length; a++) {
                            if (this.patientsData[1].series[a].name === data.patientMeasureRisk[i].month) {
                                this.patientsData[1].series[a].value = data.patientMeasureRisk[i].highRisk;
                            }
                        }
                    }
                    if (data.patientMeasureRisk[i].withourRisk !== 0) {
                        for (let a = 0; a < this.patientsData[2].series.length; a++) {
                            if (this.patientsData[2].series[a].name === data.patientMeasureRisk[i].month) {
                                this.patientsData[2].series[a].value = data.patientMeasureRisk[i].withourRisk;
                            }
                        }
                    }
                }
                this.PatientsDataRisk = this.patientsData;
            });
    }

    // select event fot diagram
    onSelect(event) {
        console.log(event);
        console.log(this.patientsData);
    }
}
