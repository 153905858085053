import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./../login/login.component.scss', './verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  private token: string;
  public success = false;
  public error = '';
  //lang;
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private localStorage: LocalStorageService
) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.params.token;
    this.success = false;
    this.apiService.verifyEmail({ token: this.token }).subscribe(
      (data: any) => {
        if (data.success === true) {
          this.localStorage.store('loggedUser', data.data);
          this.localStorage.store('token', data.data.userToken.token);
          this.router.navigate(['users/edit-profile']);
          this.success = true;
        } else {
          this.error = data.data.message;
        }
      });

  }

}
