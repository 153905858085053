import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
@Injectable({
    providedIn: 'root'
})
export class ChatMessagesCacheService {
    
    private chatMessages: any;
    private maxMessages = 300;
    constructor(
        private localStorage: LocalStorageService,
    ) 
    { 
        this.chatMessages = this.localStorage.retrieve('chat-messages');
    }

    public getChatMessages(): any {
        return this.chatMessages;
    }

    getChatMessage(messageId: number) {
        if (this.chatMessages && messageId in this.chatMessages) {
            return this.chatMessages[messageId];
        }
        return null;
    }

    addChatMessage(message: any): void {
        if (!this.chatMessages) {
            this.chatMessages = {};
        }
        //get messages length
        const messagesLength = Object.keys(this.chatMessages).length;
        //check if messages length is greater than max messages
        if(messagesLength > 0 && messagesLength >= this.maxMessages) {
            //sort messages by date
            var sortMessages = Object.values(this.chatMessages) as Array<any>;
            sortMessages.sort((a, b) => a.dateSent - b.dateSent);
            //get first element of messages
            const firstElement = sortMessages[0];
            if(firstElement.dateSent < message.dateSent) {
                //delete first element of messages
                delete this.chatMessages[firstElement._id];
                this.chatMessages[message._id] = {color: message.color, message: message.message, dateSent: message.dateSent};
            }
        } else {
            this.chatMessages[message._id] = {color: message.color, message: message.message, dateSent: message.dateSent};
        }   
        this.localStorage.store('chat-messages', this.chatMessages);
    }
}