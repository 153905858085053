import { AndroidAppInterface } from './../interfaces/android-app-interface';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { MedicalCenterService } from './medical-center-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
    private auth: AuthService,
    private injector: Injector,
    private router: Router,
    private localSt: LocalStorageService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.auth.getToken() && req.url.search(environment.apiUrl) > 0) {
            const medicalCenterService = this.injector.get(MedicalCenterService);
            var params = req.params;
            const loggedUser = this.localSt.retrieve('loggeduser');
            if (loggedUser && [301, 165].includes(loggedUser.UserID)) {
                params = params.set('lang', 'sl');
            } 
            else if ([140].includes(loggedUser.UserID)) {
                params = params.set('lang', 'en');
            } 
            else {
                params = params.set('lang', this.localSt.retrieve('currentLang'));
            }
            const medicalCenterID =  medicalCenterService.getMedicalCenterID();
            if (!params.has('medicalCenterID') && medicalCenterID != undefined) {
                params = params.set('medicalCenterID', medicalCenterID.toString());
            }
            req = req.clone({
                setHeaders: {
                    // 'Content-Type' : 'application/json; charset=utf-8',
                    // 'Accept'       : 'application/json',
                    'Authorization': 'Bearer ' + this.auth.getToken(),
                },
                // set language and medicalcenter to every request
                params: params
            });
        }


        return next.handle(req)
            .catch(err => {
                // onError
                if (err instanceof HttpErrorResponse) {
                    console.log(err.statusText);
                    // logout user if response return Unauthorized
                    if (err.status === 401) {
                        this.auth.logout();
                        const mobileAppObj = (window as any).ANDROID_OBJ as AndroidAppInterface;
                        if (mobileAppObj) {
                            this.router.navigate(['/screen/nurses-login']);
                        } else {
                            this.router.navigate(['/login']);
                        }
                    }
                }
                return Observable.throwError(err);
            }) as any;
    }
}
