import { FormGroup, FormBuilder, FormControl, ValidatorFn, FormArray, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Component, Input, OnInit } from '@angular/core';

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate (formGroup: FormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value) {
        checked ++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxesToBeChecked: true,
      };
    }

    return null;
  };
}

@Component({
  selector: 'app-ng-chat-send-to-email',
  templateUrl: './ng-chat-send-to-email.component.html',
  styleUrls: ['./ng-chat-send-to-email.component.scss'],
})

export class NgChatSendToEmailComponent implements OnInit {

  @Input() messages;
  @Input() medicalCenterID;
  form: FormGroup;
  doctorsData: Array<any> = [];
  emailSend: boolean = false;
  sending: boolean = false;
  submited: boolean = false;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.getEmails();
    
  }

  private getEmails() {
    this.apiService.getAllDoctorsByMedicalCenter(this.medicalCenterID).subscribe( x => {
      if (x.success == true) {
        this.doctorsData = x.data;
        const data = [];
        this.doctorsData.forEach(x => {
          data.push(new FormControl(x.MedicalStaffEmail, [Validators.required, Validators.email]));
        })
        this.form = this.fb.group({
          checkArray: this.fb.array(data, requireCheckboxesToBeCheckedValidator()),
        });
      }
    });
  }

  public addEmail() {
    var emails = this.form.get('checkArray') as FormArray;
    emails.push(new FormControl('', [Validators.required, Validators.email]));
  }

  public removeEmail(i) {
    const emails = this.form.get('checkArray') as FormArray;
  
    //remove element from emails by index
    if (i > -1) { // only splice array when item is found
      emails.removeAt(i);
      if (this.doctorsData[i] !== undefined){
        this.doctorsData.splice(i, 1);
      }
  
    }
  }

  get checkArray() {
    return this.form.get('checkArray');
  };

  submitForm() {
    this.submited = true;
    if (this.form.valid) {
      this.sending = true;
      var emailAddresses = [];
      this.form.value.checkArray.forEach((element, index) => {
        if (element) {
          emailAddresses.push(element);
        }
      });
      this.apiService.sendChatMessages({chatMessagesHtml: this.messages, emailAddresses: emailAddresses}).subscribe(x => {
        if (x.success == true) {
          this.emailSend = true;
          this.sending = false;
        }
      });
    }
  }

}
