import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class VitalParamsService {

    private data: any;
    constructor(private localStorage: LocalStorageService, private api$: ApiService) {
    }
    public getVitalParams(): Observable<any> {
      const vitalParams = this.localStorage.retrieve('vital-params');
      if (!vitalParams) {
        return this.api$.getMeasureParams().map(data => {
          if (data.success == true) {
            this.data = data.data;
            this.localStorage.store('vital-params', data.data);
            return data.data;
          }

        });
      } else {
        this.data = vitalParams;
        return of(vitalParams);
      }
    }
  
}