import { ResizeObserverDirective } from './../../directives/resize-observer.directive';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgChat } from './ng-chat.component';
import { NgChatSendToEmailComponent } from './components/ng-chat-send-to-email/ng-chat-send-to-email.component';
import { EmojifyPipe } from './pipes/emojify.pipe';
import { LinkfyPipe } from './pipes/linkfy.pipe';
import { KeepHtmlPipe, SanitizePipe } from './pipes/sanitize.pipe';
import { ReplaceLineBreaks } from './pipes/replace-line-breaks.pipe';
import { GroupMessageDisplayNamePipe } from './pipes/group-message-display-name.pipe';
import { NgChatOptionsComponent } from './components/ng-chat-options/ng-chat-options.component';
import { NgChatFriendsListComponent } from './components/ng-chat-friends-list/ng-chat-friends-list.component';
import { NgChatWindowComponent } from './components/ng-chat-window/ng-chat-window.component';
import { NgChatVolumeComponent } from './components/ng-chat-volume/ng-chat-volume.component';
import { EnterTheViewportNotifierDirective } from 'src/app/directives/enter-the-view-port.directive';

@NgModule({
  imports: [CommonModule, FormsModule, HttpClientModule, TranslateModule, ReactiveFormsModule],
  declarations: [
    NgChat, 
    EmojifyPipe, 
    LinkfyPipe, 
    SanitizePipe,
    KeepHtmlPipe,
    ReplaceLineBreaks,
    GroupMessageDisplayNamePipe, 
    NgChatOptionsComponent, 
    NgChatFriendsListComponent, 
    NgChatWindowComponent, 
    NgChatSendToEmailComponent,
    ResizeObserverDirective,
    EnterTheViewportNotifierDirective,
    NgChatVolumeComponent
  ],
  exports: [NgChat]
})
export class NgChatModule {
}
