import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class PurposeService {

    private data: any;
    constructor(private localStorage: LocalStorageService, private api$: ApiService) {
    }
    public getPurposes() {
      const purposes = this.localStorage.retrieve('purposes');
      if (!purposes) {
        return this.api$.getPurposes().subscribe(data => {
          if (data.success == true) {
            this.data = data.data;
            this.localStorage.store('purposes', data.data);
            return data.data;
          }

        });
      } else {
        return;
      }
    }

    public getPostPurposes() {
      return this.localStorage.retrieve('purposes');
    }

    public getGroupedPurposes() {
      const purposes = this.getPostPurposes();
      const purposeList = [];
      purposes.forEach(element => {
        if (!purposes.some(x => x.PurposeParentID == element.PurposeID)) {
          element.PurposeGroupName = element.PurposeParentID ? purposes.find(z => z.PurposeID == element.PurposeParentID).PurposeTranslationName : null;
          purposeList.push(element);
        }
      });
      return purposeList;
    }
}