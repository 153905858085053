<div class="backdrop"></div>
<div class="send-to-email">
    <ng-content>
    </ng-content>
    <div class="title" translate>Send to email</div>
    <form  *ngIf="form && emailSend == false" [formGroup]="form" class="form mark-invalid-red" [ngClass]="{'submited': submited}">
        <div class="content">
            <div class="email-person" *ngFor="let item of form.controls.checkArray.controls; let i=index">
                <input [formControl]="item" type="text"/>
                <label class="remove-email" (click)="removeEmail(i)">×</label>
                <span *ngIf="doctorsData[i]" class="doctor-email" (click)="removeEmail(i)">{{doctorsData[i]?.PersonFirstName}} {{doctorsData[i]?.PersonLastName}}</span>
            </div>
            <div (click)="addEmail()" class="add-email"  translate>Add</div>
            <div class="title sub-title" translate>Patient chat notification</div>
            <div class="chat-content" [innerHtml]="messages | keepHtml">
            </div>
        </div>
        <div class="footer"><button class="btn send" type="submit" (click)="submitForm()" [disabled]="sending" translate>Send</button></div> 
    </form>
    <div class="success-msg" *ngIf="emailSend == true">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
        <span translate>Email has beeen send successfully!</span>
    </div>
</div>
