<!-- <ng-container *ngIf="window && window.isCollapsed">
	<div class="ng-chat-title secondary-background">
		<div class="ng-chat-title-visibility-toggle-area" (click)="onChatWindowClicked(window)">
			<strong title="{{window.participant.displayName}}">
				{{window.participant.displayName}}
			</strong>
			<span [ngClass]="{'ng-chat-participant-status': true, 'online': window.participant.status == ChatParticipantStatus.Online, 'busy': window.participant.status == ChatParticipantStatus.Busy, 'away': window.participant.status == ChatParticipantStatus.Away, 'offline': window.participant.status == ChatParticipantStatus.Offline}" title="{{chatParticipantStatusDescriptor(window.participant.status, localization)}}"></span>
			<span *ngIf="unreadMessagesTotal(window).length > 0" class="ng-chat-unread-messages-count unread-messages-counter-container primary-text">{{unreadMessagesTotal(window)}}</span>
		</div>
		<a href="javascript:void(0);" class="ng-chat-close primary-text" (click)="onCloseChatWindow()">X</a>
	</div>
</ng-container> -->
<div *ngIf="window" [ngClass]="{'ng-chat-window': true, 'primary-outline-color': true, 'ng-chat-window-collapsed': window.isCollapsed, 'ng-chat-window-open': window.isOpen}" >
	<div class="ng-chat-title secondary-background">
		<div class="ng-chat-title-visibility-toggle-area" (click)="onChatWindowClicked(window)">
			<strong title="{{window.participant.displayName}}">
				{{window.participant.displayName}}
			</strong>
			<span [ngClass]="{'ng-chat-participant-status': true, 'online': window.participant.status == ChatParticipantStatus.Online, 'busy': window.participant.status == ChatParticipantStatus.Busy, 'away': window.participant.status == ChatParticipantStatus.Away, 'offline': window.participant.status == ChatParticipantStatus.Offline}" title="{{chatParticipantStatusDescriptor(window.participant.status, localization)}}"></span>
			<app-ng-chat-volume class="volume"></app-ng-chat-volume>
			<!-- <span *ngIf="unreadMessagesTotal(window).length > 0" class="ng-chat-unread-messages-count unread-messages-counter-container primary-text">{{unreadMessagesTotal(window)}}</span> -->
		</div>
		<div class="ng-chat-options-container">
			<i class="fa fa-envelope" aria-hidden="true" (click)="sendToEmail()"></i>
			<i class="fa fa-print" aria-hidden="true" (click)="print()"></i>
			<i class='fas fa-user-plus inactive' aria-hidden="true"></i>
			<i class='fas fa-phone-volume inactive' aria-hidden="true"></i>
			<i class="fas fa-video inactive" aria-hidden="true"></i>
			<i class="fa fa-info-circle inactive" aria-hidden="true"></i>
		</div>
		<ng-chat-options [ngClass]="'ng-chat-options-container'" [options]="defaultWindowOptions(window)" (activeOptionTrackerChange)="activeOptionTrackerChange($event)"></ng-chat-options>
		<i class="fa fa-times-thin" aria-hidden="true" (click)="onCloseChatWindow()"></i>
	</div>
	<div class="ng-chat-messages-cont">
		<div #chatMessages 
			(mouseenter)="onMouseEnterMessages()"
			(mouseleave)="onMouseLeaveMessages()"
			(scroll)="onScroll($event)"
			(mousemove)="onMouseEnterMessages()"
			(touchmove)="onMouseEnterMessages()"
			class="ng-chat-messages primary-background"
			>
			<!-- <div *ngIf="window.isLoadingHistory" class="ng-chat-loading-wrapper">
				<div class="loader">Loading history...</div>
			</div> -->
			<!-- <div *ngIf="hasPagedHistory && window.hasMoreMessages && !window.isLoadingHistory" class="ng-chat-load-history">
				<a class="load-history-action" (click)="fetchMessageHistory(window)">{{localization.loadMessageHistoryPlaceholder}}</a>
			</div> -->
			<div enterTheViewportNotifier [message]="message.message" (visibilityChange)="onMessageVisibilityChanged($event, message)" [enable]="readyToMarkAsSeen && message.fromId != userId && !message.dateSeen"
				id="message-{{message._id}}"
				*ngFor="let message of window.messages; let i = index" 
				[ngClass]="{'ng-chat-message': true, 'ng-chat-message-received': message.fromId != userId}">
				<ng-template #messages [ngTemplateOutlet]="messages"
				let-firstMessage="firstMessage" [ngTemplateOutletContext]="{ firstMessage: isAvatarVisible(window, message, i) }">
					<div *ngIf="firstMessage" [ngClass]="{'avatar-text': true, 'ng-chat-message-received': message.fromId != userId}">{{message.fromFirstName}} {{message.fromLastName}} {{'said' | translate}}:</div>
					<ng-container *ngIf="firstMessage && message.fromId != this.userId">
						<div *ngIf="!getChatWindowAvatar(window.participant, message)" class="icon-wrapper" title="{{message.fromFirstName}} {{message.fromLastName}}">
							<!-- <i class="user-icon"></i> -->
							<div class="avatar"  [ngClass]="{'current': message.fromId == userId }">
								{{message.fromFirstName | slice:0:1}}{{message.fromLastName | slice:0:1}}
							</div>
						</div>
						<img *ngIf="getChatWindowAvatar(window.participant, message)" alt="" class="avatar" height="30" width="30" [src]="getChatWindowAvatar(window.participant, message) | sanitize" />
						<span *ngIf="window.participant.participantType == ChatParticipantType.Group" class="ng-chat-participant-name">{{window.participant | groupMessageDisplayName:message}}</span>
					</ng-container>
					<ng-container [ngSwitch]="message.type">
						<div *ngSwitchCase="MessageType.Text" [ngClass]="{'ng-chat-first-message':firstMessage, 'sent-chat-message-container': message.fromId == userId, 'received-chat-message-container': message.fromId != userId}" (mouseenter)="showMessageID = message._id" (mouseout)="showMessageID = null">
							<a *ngIf="!message.deleted && message.fromId != this.userId && message.hidden?.length" title="show"><i class="fa fa-eye message-event"  aria-hidden="true" (click)="showHiddenMessage(i)"></i></a>
							<a *ngIf="!message.deleted && message.fromId != this.userId && !message.hidden?.length" title="hide" ><i class="fa fa-eye-slash message-event" aria-hidden="true" (click)="hideMessage(i)"></i></a>
							<a *ngIf="message.fromId == this.userId && !message.deleted" title="delete" ><i class="fa fa-trash message-event" aria-hidden="true" (click)="deleteMessage(i)"></i></a>
							<div *ngIf="message.hidden?.length || message.deleted; else shown" class="hidden-message">
								<span *ngIf="!message.deleted" translate>hidden message</span> 
								<span *ngIf="message.deleted" translate>deleted message</span> 
							</div>
							<ng-template  #shown>
								<i *ngIf="message.manualVitalAnalysisAlertID"  [ngClass]="message.color" class="fa fa-exclamation-triangle alert-icon" aria-hidden="true"></i>
								<span [innerHtml]="message.message | emojify:emojisEnabled | linkfy:linkfyEnabled | replaceLineBreaks"></span>
								<span *ngIf="message.pathologyID" [innerHtml]="message.pathologyName"></span>
							</ng-template >
							<span *ngIf="showMessageDate && message.dateSent" class="message-sent-date">{{message.dateSent | date:messageDatePipeFormat}}</span>
							<ng-container *ngIf="message.seenBy && message.seenBy.length">
								<span class="seen-by avatar" *ngFor="let seenby of message.seenBy" title="{{seenby.firstName}} {{seenby.lastName}}">{{seenby.firstName | slice:0:1}}{{seenby.lastName | slice:0:1}}</span><span class="seen-by" translate>Seen by: </span>
							</ng-container>
						</div>
						<div *ngSwitchCase="MessageType.Image"  [ngClass]="{'sent-chat-message-container': message.fromId == userId, 'received-chat-message-container': message.fromId != userId}">
							<a *ngIf="!message.deleted && message.fromId != this.userId && message.hidden?.length" title="show"><i class="fa fa-eye message-event"  aria-hidden="true" (click)="showHiddenMessage(i)"></i></a>
							<a *ngIf="!message.deleted && message.fromId != this.userId && !message.hidden?.length" title="hide" ><i class="fa fa-eye-slash message-event" aria-hidden="true" (click)="hideMessage(i)"></i></a>
							<a *ngIf="message.fromId == this.userId && !message.deleted" title="delete" ><i class="fa fa-trash message-event" aria-hidden="true" (click)="deleteMessage(i)"></i></a>
							<div *ngIf="message.hidden?.length || message.deleted; else shown" class="hidden-message">
								<span *ngIf="!message.deleted" translate>hidden message</span> 
								<span *ngIf="message.deleted" translate>deleted message</span> 
							</div>
							<ng-template  #shown>
								<ng-container *ngIf="getImageData(message.message) as messageData">
									<a class="file-details" (click)="openImageInFullScreen(message)">
										<span [innerHtml]="messageData.message | linkfy:linkfyEnabled"></span>
										<img src="{{messageData.image}}" class="image-message" />
									</a>
								</ng-container>
								<span class="pathology-name" *ngIf="message.pathologyID" [innerHtml]="message.pathologyName"></span>
							</ng-template>
							<ng-container *ngIf="message.seenBy.length">
								<span class="seen-by avatar" *ngFor="let seenby of message.seenBy" title="{{seenby.firstName}} {{seenby.lastName}}">{{seenby.firstName | slice:0:1}}{{seenby.lastName | slice:0:1}}</span><span class="seen-by" translate>Seen by: </span>
							</ng-container>
						</div>
						<div *ngSwitchCase="MessageType.File" [ngClass]="{'file-message-container': true, 'received': message.fromId != userId}">
							<a *ngIf="!message.deleted && message.fromId != this.userId && message.hidden?.length" title="show"><i class="fa fa-eye message-event"  aria-hidden="true" (click)="showHiddenMessage(i)"></i></a>
							<a *ngIf="!message.deleted && message.fromId != this.userId && !message.hidden?.length" title="hide" ><i class="fa fa-eye-slash message-event" aria-hidden="true" (click)="hideMessage(i)"></i></a>
							<a *ngIf="message.fromId == this.userId && !message.deleted" title="delete" ><i class="fa fa-trash message-event" aria-hidden="true" (click)="deleteMessage(i)"></i></a>
							<div *ngIf="message.hidden?.length || message.deleted; else shown" class="hidden-message">
								<span *ngIf="!message.deleted" translate>hidden message</span> 
								<span *ngIf="message.deleted" translate>deleted message</span> 
							</div>
							<ng-template  #shown>
								<div class="file-message-icon-container">
									<i class="paperclip-icon"></i>
								</div>
								<a class="file-details" [attr.href]="message.message" target="_blank" rel="noopener noreferrer" (click)="this.markMessagesAsRead([message])">
									<span class="file-message-title" [attr.title]="message.message">{{message.message}}</span>
									<span *ngIf="message.fileSizeInBytes"  class="file-message-size">{{message.fileSizeInBytes}} Bytes</span>
								</a>
								<span class="pathology-name" *ngIf="message.pathologyID" [innerHtml]="message.pathologyName"></span>
							</ng-template>
							<ng-container *ngIf="message.seenBy.length">
								<span class="seen-by avatar" *ngFor="let seenby of message.seenBy" title="{{seenby.firstName}} {{seenby.lastName}}">{{seenby.firstName | slice:0:1}}{{seenby.lastName | slice:0:1}}</span><span class="seen-by" translate>Seen by: </span>
							</ng-container>
						</div>
					</ng-container>
					<ng-container *ngIf="selectMessage">
						<input type="checkbox"  (change)="changeStatus(message,$event)" class="select-message">
					</ng-container>
				</ng-template>
			</div>
		</div>
	</div>
	<div class="ng-chat-footer primary-outline-color">
		<div class="ng-chat-input-container">
			<div class="ng-chat-input-options">
				<i class="fa fa-plus" aria-hidden="true"></i>
				<i class="far fa-image" aria-hidden="true"></i>
				<i class="far fa-grin" aria-hidden="true"></i>
			</div>
			<textarea #chatWindowInput
				type="text"
				[ngModel]="window.newMessage | emojify:emojisEnabled"
				(ngModelChange)="window.newMessage=$event"
				[placeholder]="localization.messagePlaceholder"
				[ngClass]="{'chat-window-input': true, 'has-side-action': fileUploadAdapter}"
				(keydown)="onChatInputTyped($event, window)"
				(blur)="toggleWindowFocus(window)"
				(focus)="toggleWindowFocus(window)"></textarea>
			<!-- File Upload -->
			<ng-container *ngIf="fileUploadAdapter">
				<a *ngIf="!isUploadingFile(window)" class="btn-add-file" (click)="triggerNativeFileUpload(window)">
					<i class="upload-icon"></i>
				</a>
				<input
					type="file"
					#nativeFileInput
					style="display: none;"
					[attr.id]="getUniqueFileUploadInstanceId(window)"
					(change)="onFileChosen(window)" />
				<div *ngIf="isUploadingFile(window)" class="loader"></div>
			</ng-container>
			<div class="send-message-icon"  (click)="sendMessage(window)">
				<svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 24 24" xml:space="preserve"><path fill="currentColor" d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z"></path></svg>
			</div>
		</div>
	</div>
</div>
<app-ng-chat-send-to-email *ngIf="selectedMessagesHtml && openSendEmail" [messages]="selectedMessagesHtml" [medicalCenterID]="window.participant.id">
	<label class="close-window" (click)="openSendEmail=false">×</label>
</app-ng-chat-send-to-email>


	<div class="image-fullscreen" *ngIf="imageInFullScreen">
		<div style="position:relative;height:100%; overflow: auto;">
			<img [src]="imageInFullScreen" (click)="imageInFullScreen = false">
		</div>
		<label class="close-window" (click)="imageInFullScreen = false">×</label>
	</div>
