import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class NewsAnnotationService {

    private data: any;
    constructor(private localStorage: LocalStorageService, private api$: ApiService) {
    }
    public getNewsAnnotations(): Observable<any> {
      const newsAnnotation = this.localStorage.retrieve('news-annotations');
      if (!newsAnnotation) {
        return this.api$.getNewsAnnotations().map(data => {
          if (data.success == true) {
            this.data = data.data;
            this.localStorage.store('news-annotations', data.data);
            return data.data;
          }

        });
      } else {
        this.data = newsAnnotation;
        return of(newsAnnotation);
      }
    }

    public async getAnnotaionByID(id: number) {
      if (!this.data) {
        this.data = this.localStorage.retrieve('news-annotations');
      }
      let find = this.data.find(x => x.NewsAnnotationID === id);
      if(!find) {
        const getNewsAnnotations = await this.api$.getNewsAnnotations().toPromise();
        if (getNewsAnnotations.success == true) {
          this.storeData(getNewsAnnotations.data);
          find = this.data.find(x => x.NewsAnnotationID === id);
        }
      }
      return find.NewsAnnotationTranslationName;
    }

    public storeData(data) {
      this.data = data;
      this.localStorage.store('news-annotations', data);
    }
  
}