<div class="login-wrap">
    <img class="login-top-image" src="assets/images/logo.svg">
    <img class="login-slogan" src="assets/images/login-saving-lives.png">
    <div  *ngIf="needTokenConfirmation == false; else needTokenConfirmationBlock">
        <form  class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit(content)">
            <div class="login-input-wrap login-text">
                <input placeholder="{{'username' | translate}}" type="text" id="inputEmail" formControlName="username" class="login-input" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autofocus>
                <div class="login-error" *ngIf="submitted && f.username.errors?.required" translate>Username is required</div>
            </div>
            <br/>
            <div class="login-input-wrap login-text">
                <input placeholder="{{'password' | translate}}" type="password" id="inputPassword" formControlName="password" class="login-input" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div class="login-error" *ngIf="submitted && f.password.errors?.required" translate>Password is required</div>
                <div class="login-error" *ngIf="submitted && f.password.errors?.minlength" translate>Password should contain at least 6 characters</div>
                <div class="login-error" *ngIf="confirmationCodeError" translate>Confirmation code has expired! Please login again</div>
            </div>
            <br/>
            <div class="login-input-wrap login-text" *ngIf="useRecaptcha">
                <re-captcha #recaptchaRef="reCaptcha" class="g-recaptcha" formControlName="recaptcha" (resolved)="addTokenLog('Reactive form mode resolved', $event)">
                </re-captcha>
                <div class="login-error recaptcha" *ngIf="submitted && f.recaptcha.errors?.required" translate>Recaptcha is required!</div>
            </div>
            <br/>
            <div class="login-input-wrap login-select">
                <select class="login-input" (change)="changeLanguage($event.target.value)" formControlName="language">
                    <option *ngFor="let language of languages" value="{{language.LanguageCode}}">{{language.LanguageName}}</option>
                </select>
            </div>
            <div class="login-input-wrap login-button">
                <button *ngIf="!submitted" class="login-input" translate>Login</button>
                <button *ngIf="submitted" type="button" class="login-input" translate><i class="fas fa-spinner fa-spin"></i></button>
            </div>
            <div class="forgot-password" translate><a routerLink="/forgot-password">Forgot password?</a></div>
        </form>
    </div>
    <ng-template #needTokenConfirmationBlock>
        <form  class="login-form" [formGroup]="confirmationForm" (ngSubmit)="sendConfirmationCode()">
            <div class="login-input-wrap login-text">
                <input placeholder="{{'Enter confirmation code from email' | translate}}" type="text" id="inputEmail" formControlName="confirmationCode" class="login-input" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autofocus>
                <div class="login-error" *ngIf="confirmationCodeError" translate>Confirmation code invalid</div>
            </div>
            <br/>
            <div class="login-input-wrap login-button">
                <button class="login-input" translate>Submit</button>
            </div>
        </form>
    </ng-template>
    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" translate>Error message</h4>
        </div>
        <div class="modal-body">

            <div class="form-group">
                <label translate>Username or password is incorect!</label>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')" translate>Try again</button>
        </div>

    </ng-template>
</div>
<img class="login-bottom-image" src="assets/images/powered-by.svg">