import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class RiskRateService {

    private riskRates = [];
    constructor(private api$: ApiService, private localStorage: LocalStorageService) {
    }

    public getRiskRates() {
      this.riskRates = this.localStorage.retrieve('riskRates');
      if (!this.riskRates) {
        this.getRiskRateFromApi().subscribe(data => {
          if (data.success == true) {
            this.riskRates = data.data;
            this.localStorage.store('riskRates', data.data);
            return data.data;
          }

        });
      }
    }
    
    public getRiskRatesObserv(): Observable<any> {
      this.riskRates = this.localStorage.retrieve('riskRates');
      if (!this.riskRates) {
        return this.getRiskRateFromApi().map(data => {
          if (data.success == true) {
            this.riskRates = data.data;
            this.localStorage.store('riskRates', data.data);
            return data.data;
          }

        });
      } else {
        return of(this.riskRates);
      }
    }
    public getRiskRateFromApi() {
      return this.api$.getRiskRate();
    }
    public checkRiskRate() {
      return (this.riskRates) ? true : false;
    }

    public findRiskRatesByParent(RiskRateID: number) {
      this.riskRates = this.localStorage.retrieve('riskRates');
      if (this.riskRates.length == 0) {
        throw new Error('Empty Risk Rates');
      }
      return this.riskRates.filter(x => x.RiskRateParentID === RiskRateID);
    }

    public findDataByRiskRateID(RiskRateID: number) {
      this.riskRates = this.localStorage.retrieve('riskRates');
      if (this.riskRates.length == 0) {
        throw new Error('Empty Risk Rates');
      }
      return this.riskRates.find(x => x.RiskRateID == RiskRateID);
    }

    public getMentalStates() {
      return this.findRiskRatesByParent(29);
    }

    public getRace() {
      return this.findRiskRatesByParent(8);

    }
    public getSelfRates() {
      return this.findRiskRatesByParent(1);
    }

    public getEducation() {
      return this.findRiskRatesByParent(20);
    }

    public getOccupations() {
      return this.findRiskRatesByParent(25);
    }

    public getLocations() {
      return this.findRiskRatesByParent(34);
    }

    public getGeneralRisk() {
      return this.findRiskRatesByParent(37);
    }

    public getCardiacRisk() {
      return this.findRiskRatesByParent(54);
    }

    public getGenders() {
      return this.findRiskRatesByParent(17);
    }

}
