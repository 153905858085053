<div class="dashboard-form-container">
    <h5 style="font-weight: bold" translate>General Statistics</h5>

    <div style="display: block; width:100%; height: 200px;" class="col-sm">
        <ngx-charts-line-chart *ngIf="patientsData[0].load" style="font-weight: bold" [scheme]="colorScheme" [results]="PatientsDataRisk" [gradient]="gradient" [xAxis]="showXAxis"
            [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
            [results]="patientsData"
            [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" (select)="onSelect($event)">
        </ngx-charts-line-chart>
    </div>

    <div class="row">
        <!-- small box -->
        <div style="width: 1515px;" class="box-sm bg-primary col-sm">
            <div class="inner">
                <h3>{{totalpashients}}</h3>
                <p translate>Total patients</p>
            </div>
            <div class="icon">
                <i class="fa fa-shopping-bag"></i>
            </div>
            <a href="#" class="box-sm-footer">{{"More info" | translate }} <i class="fa fa-arrow-circle-right"></i></a>
        </div>
        <!-- small box -->
        <div style="width: 515px;" class="box-sm bg-success col-sm">
            <div class="inner">
                <h3>{{totalConclucions}}</h3>
                <p translate>Conclusions per month</p>
            </div>
            <div class="icon">
                <i class="fa fa-shopping-bag"></i>
            </div>
            <a href="#" class="box-sm-footer">{{"More info" | translate }} <i class="fa fa-arrow-circle-right"></i></a>
        </div>
        <!-- small box -->
        <div style="width: 515px;" class="box-sm bg-warning col-sm">
            <div class="inner">
                <h3 translate>Calendar</h3>
                <p translate>Scheduling</p>
            </div>
            <div class="icon">
                <i class="fa fa-calendar"></i>
            </div>
            <a href="#" class="box-sm-footer">{{"More info" | translate }} <i class="fa fa-arrow-circle-right"></i></a>
        </div>
    </div>
</div>
