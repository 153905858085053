<div [ngClass]="{'chat-layout': showChat && medicalCenters.length, 'mobile-app': mobileAppObj}" class="screen-layout">
    <router-outlet></router-outlet>
    <div class="chat-container" [ngClass]="chatContainerClass">
        <ng-chat *ngIf="showChat && medicalCenters.length" 
            [adapter]="adapter"
            [isMobileApp]="mobileAppObj"
            [userId]="user.UserID"
            [userFirstName]="user.UserFirstName" 
            [userLastName]="user.UserLastName" 
            [participantMedicalCenterID]="patientMedicalCenter"
            [medicalCenters]="medicalCenters">
        </ng-chat>
    </div>
    <ng-template [ngIf]="!mobileAppObj">
        <input type="checkbox" name="toggle" #showScreen id="toggle">
        <label for="toggle"><span>+</span></label>
        <div id="sideBar">
            <a href="/dashboard">
                <div class="home-link">
                    <i class="fa fa-home" aria-hidden="true"></i>
                    <span translate>Home</span>
                </div>
            </a>
            <ng-template [ngIf]="currPatientID">
                <!-- <div routerLink="/screen/advanced-patient/{{currPatientID}}"  routerLinkActive="active" (click)="closeSideBar()">
                    <span class="custom-screen" translate>CREATE NEW PATIENT SCREEN</span>
                </div>
                <div *ngFor="let db of customPatientScreens" >
                    <span class="custom-screen" routerLink="/screen/advanced-patient/{{currPatientID}}/{{db.id}}"  routerLinkActive="active" (click)="closeSideBar()">{{db.name}} </span>
                    <img class="delete-screen" (click)="deleteCustomScreen(db)" src="/assets/images/screens/screen-detail/x-gray.svg">
                </div>
                <div>&nbsp;</div> -->
                <div routerLink="/screen/icu-patient/{{currPatientID}}"  routerLinkActive="active" (click)="closeSideBar()">
                    <img class="screen-img" src="/assets/images/screens/screen-detail/12-channel.jpg">
                    <span translate>ICU patient view</span>
                </div>
                <!-- <div routerLink="/screen/nightingale-patient/{{currPatientID}}"  routerLinkActive="active" (click)="closeSideBar()">
                    <img class="screen-img" src="/assets/images/screens/screen-detail/automatic-reports.png">
                    <span translate>Nightingale patient view</span>
                </div> -->
            </ng-template>
            <div routerLink="/screen/icu" routerLinkActive="active" (click)="closeSideBar()">
                <img class="screen-img" src="/assets/images/screens/screen-detail/icu-monitoring.png">
                <span translate>ICU patient view</span>
            </div>
            <!-- <div routerLink="/screen/patients-overview" routerLinkActive="active" (click)="closeSideBar()">
                <img class="screen-img" src="/assets/images/screens/screen-detail/monitoring-screens.png">
                <span translate>Patients Overview</span>
            </div> -->
            <!-- <div routerLink="/screen/nightingale" routerLinkActive="active" (click)="closeSideBar()">
                <img class="screen-img" src="/assets/images/screens/screen-detail/cardio-monitoring.jpg">
                <span translate>Nightingale monitoring</span>
            </div> -->
            <div routerLink="/screen/nightingale-ro" routerLinkActive="active" (click)="closeSideBar()">
                <img class="screen-img" src="/assets/images/screens/screen-detail/obs_dashboard.png">
                <span translate>Observation dashboard</span>
            </div>
            <div routerLink="/screen/alerts" routerLinkActive="active" (click)="closeSideBar()">
                <img class="screen-img" src="/assets/images/screens/screen-detail/general-alert.png">
                <span translate>GENERAL ALERT BOARD</span>
            </div>
            <a href="/maps">
                <div>
                    <img class="screen-img" src="/assets/images/screens/screen-detail/maps.png">
                    <span translate>Map</span>
                </div>
            </a>
        </div>
    </ng-template>
</div>
<!-- <div class="back">
	<a routerLink="/dashboard"><img src="/assets/images/screens/screen-detail/arrow-back.svg"></a>
</div> -->
