import { LocalStorageService } from 'ngx-webstorage';
import { Component, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';

import { Message } from "../../core/message";
import { MessageType } from "../../core/message-type.enum";
import { Window } from "../../core/window";
import { ChatParticipantStatus } from "../../core/chat-participant-status.enum";
import { ScrollDirection } from "../../core/scroll-direction.enum";
import { Localization } from '../../core/localization';
import { IFileUploadAdapter } from '../../core/file-upload-adapter';
import { IChatOption } from '../../core/chat-option';
import { Group } from "../../core/group";
import { ChatParticipantType } from "../../core/chat-participant-type.enum";
import { IChatParticipant } from "../../core/chat-participant";
import { MessageCounter } from "../../core/message-counter";
import { chatParticipantStatusDescriptor } from '../../core/chat-participant-status-descriptor';
import { Theme } from '../../core/theme.enum';

@Component({
    selector: 'ng-chat-window',
    templateUrl: './ng-chat-window.component.html',
    styleUrls: ['./ng-chat-window.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NgChatWindowComponent implements OnInit {
    constructor( 
        private localStorage: LocalStorageService,
        private chd: ChangeDetectorRef
    ) { }

    @Input()
    public fileUploadAdapter: IFileUploadAdapter;

    @Input()
    public window: Window;

    @Input()
    public userId: any;

    @Input()
    public theme: any;

    @Input()
    public localization: Localization;

    @Input()
    public showOptions: boolean;

    @Input()    
    public emojisEnabled: boolean = true;

    @Input()    
    public linkfyEnabled: boolean = true;

    @Input()
    public showMessageDate: boolean = true;

    @Input()
    public messageDatePipeFormat: string = "short";

    @Input()
    public hasPagedHistory: boolean = true;

    @Output()
    public onChatWindowClosed: EventEmitter<{ closedWindow: Window, closedViaEscapeKey: boolean}> = new EventEmitter();

    @Output()
    public onMessagesSeen: EventEmitter<Message[]> = new EventEmitter();

    @Output()
    public onMessageSent: EventEmitter<any> = new EventEmitter();

    @Output()
    public onMessageHide: EventEmitter<any> = new EventEmitter();

    @Output()
    public onMessageDelete: EventEmitter<any> = new EventEmitter();

    @Output()
    public onMessageUnHide: EventEmitter<any> = new EventEmitter();

    @Output()
    public onTabTriggered: EventEmitter<{ triggeringWindow: Window, shiftKeyPressed: boolean }> = new EventEmitter();

    @Output()
    public onOptionTriggered: EventEmitter<IChatOption> = new EventEmitter();

    @Output()
    public onLoadHistoryTriggered: EventEmitter<any> = new EventEmitter();

    @Output()
    public onToggleFullScreen: EventEmitter<any> = new EventEmitter();

    @ViewChild('chatMessages') chatMessages: any;
    @ViewChild('nativeFileInput') nativeFileInput: ElementRef;
    @ViewChild('chatWindowInput') chatWindowInput: ElementRef;

    // File upload state
    public fileUploadersInUse: string[] = []; // Id bucket of uploaders in use

    // Exposes enums and functions for the ng-template
    public ChatParticipantType = ChatParticipantType;
    public ChatParticipantStatus = ChatParticipantStatus;
    public MessageType = MessageType;
    public chatParticipantStatusDescriptor = chatParticipantStatusDescriptor;
    public selectMessage = false;
    public selectedMessages = [];
    public selectedMessagesHtml = null;
    public openSendEmail = false;
    public openPrint = false;
    public imageInFullScreen: any = false;
    public showMessageID;
    public hideMessageID;
    public lastScrollHeight = 0;
    public isHoverOnMessages = false;
    @Input() readyToMarkAsSeen = false;
    @Output() readyToMarkAsSeenChange = new EventEmitter<String>(); 


    ngOnInit() {
    }

    public onMouseEnterMessages() {
        this.readyToMarkAsSeen = true;
        this.isHoverOnMessages = true;
        this.chd.detectChanges();
    }

    public onMouseLeaveMessages() {
        this.readyToMarkAsSeen = false;
        this.isHoverOnMessages = false;
        this.chd.detectChanges();
    }

    defaultWindowOptions(currentWindow: Window): IChatOption[]
    {
        if (this.showOptions && currentWindow.participant.participantType == ChatParticipantType.User)
        {
            return [{
                isActive: false,
                chattingTo: currentWindow,
                validateContext: (participant: IChatParticipant) => {
                    return participant.participantType == ChatParticipantType.User;
                },
                displayLabel: 'Add People' // TODO: Localize this
            }];
        }

        return [];
    }

    // Asserts if a user avatar is visible in a chat cluster
    isAvatarVisible(window: Window, message: Message, index: number): boolean
    {
        if (index == 0){
            return true; // First message, good to show the thumbnail
        }
        else{
            // Check if the previous message belongs to the same user, if it belongs there is no need to show the avatar again to form the message cluster
            if (window.messages[index - 1].fromId != message.fromId){
                return true;
            }
        }
    }

    getImageData(message) {
        const data = message.split('-:');
        return {message: data[0], image: data[1]};
    }

    getChatWindowAvatar(participant: IChatParticipant, message: Message): string | null
    {
        return null;
        if (participant.participantType == ChatParticipantType.User)
        {
            return participant.avatar;
        }
        else if (participant.participantType == ChatParticipantType.Group)
        {
            let group = participant as Group;
            let userIndex = group.chattingTo.findIndex(x => x.id == message.fromId);

            return group.chattingTo[userIndex >= 0 ? userIndex : 0].avatar;
        }

        return null;
    }

    isUploadingFile(window: Window): boolean
    {
        const fileUploadInstanceId = this.getUniqueFileUploadInstanceId(window);

        return this.fileUploadersInUse.indexOf(fileUploadInstanceId) > -1;
    }

    // Generates a unique file uploader id for each participant
    getUniqueFileUploadInstanceId(window: Window): string
    {
        if (window && window.participant)
        {
            return `ng-chat-file-upload-${window.participant.id}`;
        }
        
        return 'ng-chat-file-upload';
    }

    unreadMessagesTotal(window: Window): string
    {           
        return MessageCounter.unreadMessagesTotal(window, this.userId);
    }

    // Scrolls a chat window message flow to the bottom
    async scrollChatWindow(window: Window, direction: ScrollDirection): Promise<void>
    {
        this.isHoverOnMessages = false;
        const currentReadyToMarkAsSeen = this.readyToMarkAsSeen;
        this.readyToMarkAsSeen = false;
        if (!window.isCollapsed){
            //setTimeout(async () => {
                if (this.chatMessages){
                    let element = this.chatMessages.nativeElement;
                    await new Promise((resolve) => {// promise will be returned and used with async/await function

                        setTimeout(async () => {
                            if (direction !== ScrollDirection.None) {
                                let position = ( direction === ScrollDirection.Top ) ? 0 : element.scrollHeight;
                                element.scrollTop = position;
                            } else {
                                element.scrollTop = element.scrollHeight - this.lastScrollHeight;
                            }
                            if (window.hasMoreMessages && element.scrollHeight <= element.offsetHeight) {
                                await this.fetchMessageHistory(window);
                            }
                            
                            //setTimeout(() => {
                                this.readyToMarkAsSeen = currentReadyToMarkAsSeen;       
                            //}, 100);
                            resolve(true); // when time will pass, than resolve promise
                        }); // set wait time in miliseconds
                    
                      });

                }
            //}); 
        } else {
            return;
        }
    }

    activeOptionTrackerChange(option: IChatOption): void {
        this.onOptionTriggered.emit(option);
    }

    // Triggers native file upload for file selection from the user
    triggerNativeFileUpload(window: Window): void
    {
        if (window)
        {
            if (this.nativeFileInput) this.nativeFileInput.nativeElement.click();
        }
    }

    // Toggles a window focus on the focus/blur of a 'newMessage' input
    toggleWindowFocus(window: Window): void
    {   
        window.hasFocus = !window.hasFocus;
        if(window.hasFocus) {
            this.scrollChatWindow(window, ScrollDirection.Bottom);
            // const unreadMessages = window.messages
            //     .filter(message => message.dateSeen == null 
            //         && (message.toId == this.userId || window.participant.participantType === ChatParticipantType.Group));
            // const unreadMessages = window.messages
            //     .filter(message => !message.dateSeen && message.fromId != this.userId
            //        );
            // if (unreadMessages && unreadMessages.length > 0)
            // {
            //     this.onMessagesSeen.emit(unreadMessages);
            // }
        }
    }

    markMessagesAsRead(messages: Message[]): void 
    {
        this.onMessagesSeen.emit(messages);
    }

    async fetchMessageHistory(window: Window): Promise<void> {
        this.lastScrollHeight = this.chatMessages.nativeElement.scrollHeight;
        await (new Promise(resolve => {
            this.onLoadHistoryTriggered.emit({window: window, resp: (resp => {
                if (resp == true) {
                    resolve(true);
                }
            })});
          }));
    }

    // Closes a chat window via the close 'X' button
    onCloseChatWindow(): void 
    {
        this.onChatWindowClosed.emit({ closedWindow: this.window, closedViaEscapeKey: false });
    }

    /*  Monitors pressed keys on a chat window
        - Dispatches a message when the ENTER key is pressed
        - Tabs between windows on TAB or SHIFT + TAB
        - Closes the current focused window on ESC
    */
   onChatInputTyped(event: any, window: Window): void
   {
       switch (event.keyCode)
       {
           case 13:
               if (!event.shiftKey) {
                this.sendMessage(window);
               }
               break;
           case 9:
               event.preventDefault();
               this.onTabTriggered.emit({ triggeringWindow: window, shiftKeyPressed: event.shiftKey });
               break;
           case 27:
               this.onChatWindowClosed.emit({ closedWindow: window, closedViaEscapeKey: true });
               break;
       }
   }

   sendMessage(window) {
       if (window.newMessage && window.newMessage.trim() != "") {
           let message = new Message();
           const user = this.localStorage.retrieve('loggeduser');
           message.fromId = this.userId;
           message.fromMedCenterID = user.userMedicalCenter.MedicalCenterID;
           message.fromFirstName = user.UserFirstName;
           message.fromLastName = user.UserLastName;
           message.toId = window.participant.id;
           window.newMessage = window.newMessage.trim();
           message.message = window.newMessage;
           message.dateSent = new Date();
           this.onMessageSent.emit({
               message: message, resp: (resp => {
                   if (resp.success == true) {
                       message._id = resp.data;
                       window.messages.push(message);
                       this.scrollChatWindow(window, ScrollDirection.Bottom);
                   }
               })
           });
           window.newMessage = ''; // Resets the new message input
           setTimeout(() => {
               const textArea = this.chatWindowInput.nativeElement as HTMLTextAreaElement;
               if (textArea) {
                   textArea.focus();
                   textArea.setSelectionRange(0, 0);
               }
           });
       }
    }

    // Toggles a chat window visibility between maximized/minimized
    onChatWindowClicked(window: Window): void
    {
        //window.isCollapsed = !window.isCollapsed;
        this.onToggleFullScreen.emit();
        this.scrollChatWindow(window, ScrollDirection.Bottom);
    }

    private clearInUseFileUploader(fileUploadInstanceId: string): void
    {
        const uploaderInstanceIdIndex = this.fileUploadersInUse.indexOf(fileUploadInstanceId);

        if (uploaderInstanceIdIndex > -1) {
            this.fileUploadersInUse.splice(uploaderInstanceIdIndex, 1);
        }
    }

    // Handles file selection and uploads the selected file using the file upload adapter
    onFileChosen(window: Window): void {
        const fileUploadInstanceId = this.getUniqueFileUploadInstanceId(window);
        const uploadElementRef = this.nativeFileInput;

        if (uploadElementRef)
        {
            const file: File = uploadElementRef.nativeElement.files[0];

            this.fileUploadersInUse.push(fileUploadInstanceId);

            this.fileUploadAdapter.uploadFile(file, window.participant.id)
                .subscribe(fileMessage => {
                    this.clearInUseFileUploader(fileUploadInstanceId);

                    fileMessage.fromId = this.userId;

                    // Push file message to current user window   
                    window.messages.push(fileMessage);
        
                    this.onMessageSent.emit(fileMessage);
        
                    this.scrollChatWindow(window, ScrollDirection.Bottom);

                    // Resets the file upload element
                    uploadElementRef.nativeElement.value = '';
                }, (error) => {
                    this.clearInUseFileUploader(fileUploadInstanceId);

                    // Resets the file upload element
                    uploadElementRef.nativeElement.value = '';

                    // TODO: Invoke a file upload adapter error here
                });
        }
    }

    sendToEmail() {
        if (this.selectMessage == false) {
            this.selectMessage = true;
            this.selectedMessages = [];
        } else if (this.selectedMessages.length) {
            this.sendSelectedMessages();
        }
    }

    print() {
        if (this.selectMessage == false) {
            this.selectMessage = true;
            this.selectedMessages = [];
        } else if (this.selectedMessages.length) {
            this.printSelectedMessages();
        }
    }

    private sendSelectedMessages() {

        this.constructMessagesHtml();
        this.openSendEmail = true;
    }

    private constructMessagesHtml() {
        var sendBackgroundColor = '';
        var receiveBackgroundColor = '';
        var backgroundColor = '';
        var color = '';
        if (this.theme ==  Theme.Dark) {
            sendBackgroundColor = '#373D46';
            receiveBackgroundColor = '#2B3038';
            backgroundColor = '#323944';
            color = '#FFF';
        } else {
            sendBackgroundColor = '#D4EFFF';
            receiveBackgroundColor = '#F7F7F9';
            backgroundColor = '#fff';
            color = '#595959';
        }
        var html = '<div class="light-theme" style="color: ' + color + ';background-color:' + backgroundColor + '; display: flow-root"><div class="ng-chat-window primary-outline-color ng-chat-window-open"><div class="ng-chat-messages-cont"><div class="ng-chat-messages">';
        this.selectedMessages.sort();
        this.selectedMessages.forEach(x => {
            html += document.getElementById('message-' + x).outerHTML;
        });
        html += '</div></div></div></div>';
        const replaces = {
            'fa fa-exclamation-triangle alert-icon': 'font-size: 1.3vw; position: absolute; right: 10px;',
            'select-message': 'display: none',
            'avatar-text': 'width: 100%; text-align: right',
            'avatar-text ng-chat-message-received': 'width: 100%; text-align: left',
            'seen-by': 'float: right; font-size: 8px',
            'chat-content': 'box-sizing: border-box;',
            'sent-chat-message-container': 'background-color: ' + sendBackgroundColor +'; border-color: ' + sendBackgroundColor + '; float: right; max-width: 100%; padding: 0; margin-top: 0; border-radius: 20px; padding: 10px; margin-top: 0; margin-bottom: 5px; font-size: 0.9em; word-wrap: break-word; position: relative;',
            'ng-chat-first-message sent-chat-message-container': 'background-color: ' + sendBackgroundColor + '; border-color: ' + sendBackgroundColor +'; float: right; max-width: 100%; padding: 0; margin-top: 0; border-radius: 20px; padding: 10px; margin-top: 0; margin-bottom: 5px; font-size: 0.9em; word-wrap: break-word; position: relative;',
            //'icon-wrapper': 'background-color: #BABABA; overflow: hidden; width: 30px; height: 30px; padding: 0; position: absolute; left: 10px; border-radius: 25px; background-color: #BABABA; overflow: hidden; width: 30px; height: 30px; padding: 0;',
            'icon-wrapper': 'display: none',
            'ng-chat-first-message received-chat-message-container': 'border-color: ' + receiveBackgroundColor +'; float: left; padding-top: 7px; padding-bottom: 7px; border-style: solid; border-width: 3px; margin-top: 0; margin-bottom: 5px; border-radius: 0 20px 20px 20px !important; background-color: ' + receiveBackgroundColor + '; border-color: ' + receiveBackgroundColor + ';',
            'received-chat-message-container': 'border-color: ' + receiveBackgroundColor + '; float: left; padding-top: 7px; padding-bottom: 7px; border-style: solid; border-width: 3px; margin-top: 0; margin-bottom: 5px; border-radius: 20px; background-color: ' + receiveBackgroundColor + '; border-color: ' + receiveBackgroundColor + ';',
            'ng-chat-message': 'float: right; clear: both; display: -webkit-box; display:-webkit-flex; display: flex; flex-wrap: wrap; -webkit-box-align: center; align-items: center; -webkit-box-orient: horizontal; -webkit-box-direction: reverse; flex-direction: row-reverse;',
            'ng-chat-message ng-chat-message-received': 'clear: both; float: left; display: -webkit-box; display:-webkit-flex; display: flex; flex-wrap: wrap; -webkit-box-align: center; align-items: center;',
            'message-sent-date': 'font-size: 0.8em; display: block; text-align: right; margin-top: 5px;',
            'avatar': 'width: 32px; height: 32px; border-radius: 50%; font-size: 14px; color: #fff; text-align: center; line-height: 32px; cursor: pointer; background: #31926F;',
            'seen-by avatar': ' width: 32px; height: 32px; border-radius: 50%; font-size: 14px; color: #fff; text-align: center; line-height: 32px; cursor: pointer; background: #31926F; float: right; height: 10px; width: 10px; line-height: 10px; font-size: 8px; margin-left: 1px; text-transform: lowercase;',
        }
        for (var key in replaces) {
            const search = 'class="' + key + '"';
            const replaceWith = 'style="' + replaces[key] + '"';
            html = html.split(search).join(replaceWith);
        }
        this.selectedMessagesHtml = html;
    }

    private printSelectedMessages() {
        this.constructMessagesHtml();
        this.printElem(this.selectedMessagesHtml);
    }

    private printElem(html)
    {
        var mywindow = window.open('', 'PRINT');
    
        mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        mywindow.document.write('<style>body {color: #595959;font-family: Arial, Helvetica, sans-serif; } @media print {body {-webkit-print-color-adjust: exact;}}</style>');
        mywindow.document.write('<link rel="stylesheet" href="http://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css">');
        mywindow.document.write('</head><body >');
        mywindow.document.write(html);
        mywindow.document.write('</body></html>');
    
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        setTimeout(() => {
            mywindow.print();
            mywindow.close();            
        }, 200);
    
        return true;
    }

    changeStatus(message, event) {
        if (event.target.checked) {
            this.selectedMessages.push(message._id);
        } else {
            const index = this.selectedMessages.indexOf(message._id);
            if (index > -1) {
                this.selectedMessages.splice(index, 1);
            }
        }
    }

    openImageInFullScreen(message) {
        this.imageInFullScreen = this.getImageData(message.message).image;
        this.markMessagesAsRead([message]);
    }

    showHiddenMessage(i) {
        var message = this.window.messages[i] as any;
        this.onMessageUnHide.emit({
            message: {messageID: message._id, userID: this.userId}, resp: (resp => {
                if (resp.success == true) {
                    message.hidden = [];
                }
            })
        });

    }

    hideMessage(i) {
        var message = this.window.messages[i] as any
        this.onMessageHide.emit({
            message: {messageID: message._id, userID: this.userId}, resp: (resp => {
                if (resp.success == true) {
                    message.hidden = [this.userId];
                }
            })
        });
    }

    deleteMessage(i) {
        var message = this.window.messages[i] as any
        this.onMessageDelete.emit({
            message: {messageID: message._id}, resp: (resp => {
                if (resp.success == true) {
                    message.deleted = true;
                }
            })
        });
    }

    onScroll(event: any) {
        this.readyToMarkAsSeen = this.isHoverOnMessages;
        // visible height + pixel scrolled >= total height 
        if (event.target.scrollTop == 0 && this.window.messages.length > 0 && this.window.hasMoreMessages) {
          this.fetchMessageHistory(this.window)
        }
    }

    onMessageVisibilityChanged(event: any, message) {
        if (this.readyToMarkAsSeen && event == 'VISIBLE') {
            this.markMessagesAsRead([message]);
        }
    }

    enterMessages() {
        this.readyToMarkAsSeen = true;
    }
      
 }
