import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    './forgot-password.component.scss',
    './../../login/login.component.scss',
  ]
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  success = false;
  errorMessageFromServer = false;
  sendToEmail = null;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    //private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (this.loading === false) {
      this.submitted = true;
      // stop here if form is invalid
      if (this.form.invalid) {
        return;
      }
      this.loading = true;
      this.sendToEmail = null;
      this.errorMessageFromServer = false;
      this.apiService.requestPasswordReset(this.form.value)
        .subscribe(
          data => {
            if (data.success == true) {
              this.success = true;
              this.sendToEmail = data.data.email;
              // this.router.navigate(['/login']).then(()=>{
              //   this.messagingService.setMessage('Check your email for further instructions.', 'message');
              // });
            } else {
              this.errorMessageFromServer = data.data.message;
            }
            this.loading = false;
          },
          error => {
            this.loading = false;
          });
    }
  }
}
