import { AndroidAppInterface } from '../interfaces/android-app-interface';
import { ReactNativeCommunicator } from '../interfaces/react-native-comunicator';

export function mixinMobileApp(BaseClass) {
    return class extends BaseClass {
        public mobileAppObj;
        constructor(...args: any[]) {
            super(...args);
            const reactNative = (window as any).ReactNativeWebView;
            if (reactNative) {
                this.mobileAppObj = new ReactNativeCommunicator();
            } else {
                const android = (window as any).ANDROID_OBJ as AndroidAppInterface;
                if (android) {
                    this.mobileAppObj = android;
                }
            }
        }
    }
}
