<div class="form-group password">
    <label translate>Password strength</label>
    <div class="progressbar">
        <div [style.width.%]="percentage" [ngClass]="color"></div>
    </div>
    <div class="requirements">
        <span>
            <i [ngClass]="this.min ? 'fa-check-circle color-green' : 'fa-times color-red' " class="fa"
               aria-hidden="true"></i>
            {{'Minimum 8 characters' | translate }}
        </span>
        <span>
            <i [ngClass]="this.small ? 'fa-check-circle color-green' : 'fa-times color-red' " class="fa"
               aria-hidden="true"></i>
            {{ 'Small letters' | translate }}
        </span>
        <span>
            <i [ngClass]="this.capital ? 'fa-check-circle color-green' : 'fa-times color-red' " class="fa"
               aria-hidden="true"></i>
            {{'Capital letters' | translate }}
        </span>
        <span>
            <i [ngClass]="this.digit ? 'fa-check-circle color-green' : 'fa-times color-red' " class="fa"
               aria-hidden="true"></i>
            {{'Digits' | translate }}
        </span>
        <span>
            <i [ngClass]="this.special ? 'fa-check-circle color-green' : 'fa-times color-red' " class="fa"
               aria-hidden="true"></i>
            {{'Special symbols' | translate }}
        </span>
    </div>
</div>