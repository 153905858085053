import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class CountryService {

    private countries = [];
    constructor(private api$: ApiService, private localStorage: LocalStorageService) {
    }

    public getCountriesFromApi() {
      this.countries = this.localStorage.retrieve('countries');
      if (!this.countries) {
        return this.api$.getContries().subscribe(data => {
          if (data.success == true) {
            this.countries = data.data;
            this.localStorage.store('countries', data.data);
            return data.data;
          }

        });
      } else {
        return this.countries;
      }
    }

    public getCountries() {
      return this.localStorage.retrieve('countries');
    }

    public findCountryByID(id: number) {
      this.countries = this.localStorage.retrieve('countries');
      if (this.countries.length == 0) {
        throw new Error('Empty counries');
      }
      return this.countries.find(x => x.CountryID === id);
    }
}
