import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class UrgentAlertTemplateService {

    private data: any;
    constructor(private localStorage: LocalStorageService, private api$: ApiService) {
    }
    public getTemplates(): Observable<any> {
      const templates = this.localStorage.retrieve('urgent-alert-templates_v1');
      if (!templates) {
        return this.api$.getUrgentAlertTemplate().map(data => {
          console.log('get templates', templates);
          if (data.success == true) {
            this.data = data.data;
            this.localStorage.store('urgent-alert-templates_v1', data.data);
            return data.data;
          }

        });
      } else {
        this.data = templates;
        return of(templates);
      }
    }  

    public getTemplateByID(id: number) {
      if (!this.data) {
        this.data = this.localStorage.retrieve('urgent-alert-templates_v1');
      }
      const find = this.data.find(x => x.UrgentAlertTemplateID === id);
      return find ? find.UrgentAlertTemplateTranslationName : '';
    }
}