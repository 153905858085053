import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisitDataService {

    private visitDataSubject = new BehaviorSubject<any>({});

    getVisitData(){
      return this.visitDataSubject.asObservable();
    }

    setVisitData(data) {
      this.visitDataSubject.next(data);
    }

    getVisitDataValue() {
      return this.visitDataSubject.getValue();
    }

}