import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
    private localStorage: LocalStorageService,
    ) {
    }

    public isAuthenticated(): boolean {
        return !!this.localStorage.retrieve('token');
    }

    public getToken() {
        return this.localStorage.retrieve('token');
    }
    public getLanguage() {
        return this.localStorage.retrieve('currentlang') || 'en';
    }
    public getPermissions(): string[] {
        if (!this.isAuthenticated()) {
            return [];
        }
        return this.localStorage.retrieve('loggeduser').userPermissions;
    }

    public logout() {

        this.localStorage.clear('loggedUser');
        this.localStorage.clear('token');
        this.clearLocalStorageData();
    }

    clearLocalStorageData() {
        this.localStorage.clear('medical-centers');
        //this.localStorage.clear('selectedMedcenter');
        this.localStorage.clear('riskRates');
        this.localStorage.clear('purposes');
        this.localStorage.clear('countries');
        this.localStorage.clear('medicalCodes');
        this.localStorage.clear('news-annotation');
        this.localStorage.clear('news-annotations');
        this.localStorage.clear('parameters');
        this.localStorage.clear('ekg-annotations_v2');
        this.localStorage.clear('urgent-alert-templates_v1');
        this.localStorage.clear('measureParams');
        this.localStorage.store('patient-screen', '');
    }

    public generatePassword(len: number) {
        const length = (len) ? (len) : (8);
        const string = 'abcdefghijklmnopqrstuvwxyz'; // to upper
        const numeric = '0123456789';
        const punctuation = '-!@#$%^&';
        let password = '';
        let character = '';
        while (password.length < length) {
            const entity1 = Math.ceil(string.length * Math.random() * Math.random());
            const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
            const entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
            const entity4 = Math.ceil(punctuation.length * Math.random() * Math.random());
            character += string.charAt(entity1);
            character += string.charAt(entity4).toUpperCase();
            character += numeric.charAt(entity2);
            character += punctuation.charAt(entity3);
            password = character;
        }
        password = password.split('').sort(function () { return 0.5 - Math.random(); }).join('');

        return password.substr(0, length);
    }

}
