import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class HeadingService {
    public title = new Subject<any>();

    constructor(
        private translate$: TranslateService,
    ) {}

    public getTitleTranslatedText(title: any) {
        let text = this.translate$.instant(title.text);
        if (title.additional) {
            text = text + ' ' + title.additional;
        }
        return text;
    }
}
