import { Message } from 'src/app/components/ng-chat/core/message';
import { EkgAnnotationService } from 'src/app/services/ekg-annotation-service';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { UrgentAlertTemplateService } from 'src/app/services/urgent-alert-template-service';
import { Component, Input, OnInit, ViewChildren, QueryList, HostListener, Output, EventEmitter, ViewEncapsulation, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChatAdapter } from './core/chat-adapter';
import { getColorByLevel } from 'src/app/funcs/funcs';
import { IChatGroupAdapter } from './core/chat-group-adapter';
import { User } from './core/user';
import { ParticipantResponse } from './core/participant-response';
import { MessageType } from './core/message-type.enum';
import { Window } from './core/window';
import { ChatParticipantStatus } from './core/chat-participant-status.enum';
import { ScrollDirection } from './core/scroll-direction.enum';
import { Localization, StatusDescription } from './core/localization';
import { IChatController } from './core/chat-controller';
import { PagedHistoryChatAdapter } from './core/paged-history-chat-adapter';
import { IFileUploadAdapter } from './core/file-upload-adapter';
import { DefaultFileUploadAdapter } from './core/default-file-upload-adapter';
import { Theme } from './core/theme.enum';
import { IChatOption } from './core/chat-option';
import { Group } from './core/group';
import { ChatParticipantType } from './core/chat-participant-type.enum';
import { IChatParticipant } from './core/chat-participant';
import { map, switchMap } from 'rxjs/operators';
import { NgChatWindowComponent } from './components/ng-chat-window/ng-chat-window.component';
import { ApiService } from 'src/app/services/api.service';
import { NewsAnnotationService } from 'src/app/services/news-annotation-service';
import { ChatMessagesCacheService } from 'src/app/services/chat-messages-cache.services';
import { NgChatVolumeService } from 'src/app/components/ng-chat/components/ng-chat-volume/ng-chat-volume.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ng-chat',
  templateUrl: 'ng-chat.component.html',
  styleUrls: [
    'assets/icons.css',
    'assets/loading-spinner.css',
    'assets/ng-chat.component.default.css',
    'assets/themes/ng-chat.theme.default.scss',
    'assets/themes/ng-chat.theme.dark.scss'
  ],
  encapsulation: ViewEncapsulation.None
})

export class NgChat implements OnInit, IChatController, OnDestroy {
  constructor(
        private _httpClient: HttpClient,
        private apiService: ApiService,
        private localStorage: LocalStorageService,
        private urgentTemplateService: UrgentAlertTemplateService,
        private newsAnnotationService: NewsAnnotationService,
        private ekgAnnotationService: EkgAnnotationService,
        private translateService: TranslateService,
        private changeDetRef: ChangeDetectorRef,
        private cachedMessages: ChatMessagesCacheService,
        private volumeService: NgChatVolumeService
  ) { 
    //this.visibilityChangeCallback = () => this.handleVisibilityChange();
    document.addEventListener('visibilitychange', this.visibilityChangeCallback, true);
    this.getTheme();
    //subscribe to local storage changes
    this.localStorage.observe('darkTheme').subscribe((theme) => {
      this.getTheme();
    });

    this.volumeService.eventEmitter.subscribe((value) => {
      console.log('value', value);
      this.emitNewMessageSound();
    });
  } 
    private visibilityChangeCallback: () => void;
    // Exposes enums for the ng-template
    public ChatParticipantType = ChatParticipantType;
    public ChatParticipantStatus = ChatParticipantStatus;
    public MessageType = MessageType;

    private _isDisabled = false;
    private maxMessages = 300;

    get isDisabled(): boolean {
      return this._isDisabled;
    }

    @Input()
    set isDisabled(value: boolean) {
      this._isDisabled = value;

      if (value) {
        // To address issue https://github.com/rpaschoal/ng-chat/issues/120
        window.clearInterval(this.pollingIntervalWindowInstance);
      }
      else {
        this.activateFriendListFetch();
      }
    }

    @Input()
    public adapter: ChatAdapter;

    @Input()
    public isMobileApp: false;

    @Input()
    public groupAdapter: IChatGroupAdapter;

    @Input()
    public userId: any;

    @Input()
    public userFirstName: string;

    @Input()
    public userLastName: string;

    @Input()
    public participantMedicalCenterID: any;

    @Input()
    public medicalCenters: any;

    @Input()
    public isCollapsed = true;

    @Input()
    public maximizeWindowOnNewMessage = true;

    @Input()
    public pollFriendsList = false;

    @Input()
    public pollingInterval = 5000;

    @Input()
    public historyEnabled = true;

    @Input()
    public emojisEnabled = true;

    @Input()
    public linkfyEnabled = true;

    @Input()
    public audioEnabled = true;

    @Input()
    public searchEnabled = true;

    @Input() // TODO: This might need a better content strategy
    public audioSource = 'https://raw.githubusercontent.com/rpaschoal/ng-chat/master/src/ng-chat/assets/notification.wav';

    @Input()
    public persistWindowsState = true;

    @Input()
    public title = 'You have new messages';

    @Input()
    public messagePlaceholder = 'Type a message';

    @Input()
    public searchPlaceholder = 'Search...';

    @Input()
    public browserNotificationsEnabled = true;

    @Input() // TODO: This might need a better content strategy
    public browserNotificationIconSource = 'https://raw.githubusercontent.com/rpaschoal/ng-chat/master/src/ng-chat/assets/notification.png';

    @Input()
    public browserNotificationTitle = 'New message from';

    @Input()
    public historyPageSize = 10;

    @Input()
    public localization: Localization;

    @Input()
    public hideFriendsList = false;

    @Input()
    public hideFriendsListOnUnsupportedViewport = true;

    @Input()
    public fileUploadUrl: string;

    @Input()
    public theme: Theme = Theme.Light;

    @Input()
    public customTheme: string;

    @Input()
    public messageDatePipeFormat = 'short';

    @Input()
    public showMessageDate = true;

    @Input()
    public isViewportOnMobileEnabled = true;

    @Input()
    public fileUploadAdapter: IFileUploadAdapter;

    @Input()
    public openWindowOnNewMessage = true;

    @Output()
    public onParticipantClicked: EventEmitter<IChatParticipant> = new EventEmitter<IChatParticipant>();

    @Output()
    public onParticipantChatOpened: EventEmitter<IChatParticipant> = new EventEmitter<IChatParticipant>();

    @Output()
    public onParticipantChatClosed: EventEmitter<IChatParticipant> = new EventEmitter<IChatParticipant>();

    @Output()
    public onMessagesSeen: EventEmitter<Message[]> = new EventEmitter<Message[]>();

    public subscriptions = [];

    private browserNotificationsBootstrapped = false;

    public hasPagedHistory = false;

    // Don't want to add this as a setting to simplify usage. Previous placeholder and title settings available to be used, or use full Localization object.
    private statusDescription: StatusDescription = {
      online: 'Online',
      busy: 'Busy',
      away: 'Away',
      offline: 'Offline'
    };

    private audioFile: HTMLAudioElement;

    public participants: IChatParticipant[];

    public participantsResponse: ParticipantResponse[];

    public participantsInteractedWith: IChatParticipant[] = [];

    public currentActiveOption: IChatOption | null;

    private pollingIntervalWindowInstance: number;

    private get localStorageKey(): string {
      return `ng-chat-users-${this.userId}`; // Appending the user id so the state is unique per user in a computer.
    }

    // Defines the size of each opened window to calculate how many windows can be opened on the viewport at the same time.
    public windowSizeFactor = 320;

    // Total width size of the friends list section
    public friendsListWidth = 262;

    // Available area to render the plugin
    private viewPortTotalArea: number;

    // Set to true if there is no space to display at least one chat window and 'hideFriendsListOnUnsupportedViewport' is true
    public unsupportedViewport = false;

    public userMedicalCenterID: any;

    public lang;

    windows: Window[] = [];
    isBootstrapped = false;
    totalMessages = 0;
    fullChat = false;
    fullScreen = false;
    openParticipantOnCollapse = null;
    @ViewChild('chatContainer')
    myIdentifier: ElementRef;
    readyToMarkAsSeen = false;
    @LocalStorage() chatVolume;
    @ViewChildren('chatWindow') chatWindows: QueryList<NgChatWindowComponent>;

    @HostListener('window:processMessageChat', ['$event'])
    processMessageChat(event: CustomEventInit) {
      this.processMessageFromAndroid(event.detail);
    }
    @HostListener('window:refreshData')
    refreshData() {
      this.getTotalMessages();
    }

    ngOnInit() {
      this.userMedicalCenterID = this.localStorage.retrieve('loggedUser').userMedicalCenter.MedicalCenterID;
      this.bootstrapChat();
      if ([301, 165].includes(this.userId)) {
        this.lang = 'sl';
      } else {
        this.lang = this.localStorage.retrieve('currentlang');
      }
      this.getTotalMessages();
      if (this.totalMessages > 0) {
        this.isCollapsed = false;
      }
      this.setPlaceHolderTranslations();
      this.subscriptions.push(this.localStorage.observe('participant-response').subscribe( data => {
        if (data) {
          this.participantsResponse = data;
          this.getTotalMessages();
        }
      })
      );
    }
    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
      if (event.state == 'open-chat') {
        if (this.isCollapsed === false){
          this.isCollapsed = true;
        }
      } else if(event.state == 'chat-participant-clicked') {
        this.closeAllWindows();
        this.isCollapsed = false;
      }
    }
    @HostListener('window:resize', ['$event'])
    onResizeEvent(event: any) {
      this.viewPortTotalArea = event.target.innerWidth;
      //this.NormalizeWindows();
    }

    private setPlaceHolderTranslations() {
      this.messagePlaceholder = this.translateService.instant('Type a message');
      this.searchPlaceholder = this.translateService.instant('Search');
    }

    // Checks if there are more opened windows than the view port can display
    private NormalizeWindows(): void {
      const maxSupportedOpenedWindows = Math.floor((this.viewPortTotalArea - (!this.hideFriendsList ? this.friendsListWidth : 0)) / this.windowSizeFactor);
      const difference = this.windows.length - maxSupportedOpenedWindows;

      if (difference >= 0) {
        this.windows.splice(this.windows.length - difference);
      }

      this.updateWindowsState(this.windows);
      // Viewport should have space for at least one chat window but should show in mobile if option is enabled.
      this.unsupportedViewport = this.isViewportOnMobileEnabled ? false : this.hideFriendsListOnUnsupportedViewport && maxSupportedOpenedWindows < 1;
    }

    getTotalMessages() {
      const totalUnreadMessages = this.participantsResponse
        .map((participantResponse) => {
          return participantResponse.metadata.totalUnreadMessages;
        });
      this.totalMessages = totalUnreadMessages.reduce(function(a, b){
        return a + b;
      }, 0);
    }

    setParticipantsResponseToLocalStorage(participantResponse) {
      this.localStorage.clear('participant-response');
      this.localStorage.store('participant-response', participantResponse);
    }

    // Initializes the chat plugin and the messaging adapter
    private bootstrapChat(): void {
      let initializationException = null;

      if (this.adapter != null && this.userId != null) {
        try {
          this.viewPortTotalArea = window.innerWidth;

          this.initializeTheme();
          this.initializeDefaultText();
          this.initializeBrowserNotifications();

          // Binding event listeners
          this.adapter.messageReceivedHandler = (participant, msg) => this.onMessageReceived(participant, msg);
          this.adapter.messageSeenHandler = (msg) => this.onMessageSeen(msg);
          this.adapter.disconnectHandler = () => this.onDisconnect();
          this.adapter.friendsListChangedHandler = (participantsResponse) => this.onFriendsListChanged(participantsResponse);

          this.activateFriendListFetch();

          this.bufferAudioFile();

          this.hasPagedHistory = this.adapter instanceof PagedHistoryChatAdapter;

          if (this.fileUploadUrl && this.fileUploadUrl !== '') {
            this.fileUploadAdapter = new DefaultFileUploadAdapter(this.fileUploadUrl, this._httpClient);
          }

          this.NormalizeWindows();

          this.isBootstrapped = true;
        }
        catch (ex) {
          initializationException = ex;
        }
      }

      if (!this.isBootstrapped) {
        console.error('ng-chat component couldn\'t be bootstrapped.');

        if (this.userId == null) {
          console.error('ng-chat can\'t be initialized without an user id. Please make sure you\'ve provided an userId as a parameter of the ng-chat component.');
        }
        if (this.adapter == null) {
          console.error('ng-chat can\'t be bootstrapped without a ChatAdapter. Please make sure you\'ve provided a ChatAdapter implementation as a parameter of the ng-chat component.');
        }
        if (initializationException) {
          console.error(`An exception has occurred while initializing ng-chat. Details: ${initializationException.message}`);
          console.error(initializationException);
        }
      }
    }

    private activateFriendListFetch(): void {
      if (this.adapter) {
        // Loading current users list
        if (this.pollFriendsList) {
          // Setting a long poll interval to update the friends list
          this.fetchFriendsList(true);
          this.pollingIntervalWindowInstance = window.setInterval(() => this.fetchFriendsList(false), this.pollingInterval);
        }
        else {
          // Since polling was disabled, a friends list update mechanism will have to be implemented in the ChatAdapter.
          this.fetchFriendsList(true);
        }
      }
    }

    // Initializes browser notifications
    private async initializeBrowserNotifications() {
      if (this.browserNotificationsEnabled && ('Notification' in window)) {
        if (await Notification.requestPermission() === 'granted') {
          this.browserNotificationsBootstrapped = true;
        }
      }
    }

    // Initializes default text
    private initializeDefaultText(): void {
      if (!this.localization) {
        this.localization = {
          messagePlaceholder: this.translateService.instant(this.messagePlaceholder),
          searchPlaceholder: this.translateService.instant(this.searchPlaceholder),
          title: this.translateService.instant(this.title),
          statusDescription: this.statusDescription,
          browserNotificationTitle: this.translateService.instant(this.browserNotificationTitle),
          loadMessageHistoryPlaceholder: this.translateService.instant('Load older messages')
        };
      }
    }

    private initializeTheme(): void {
      if (this.customTheme) {
        this.theme = Theme.Custom;
      }
      else if (this.theme != Theme.Light && this.theme != Theme.Dark) {
        // TODO: Use es2017 in future with Object.values(Theme).includes(this.theme) to do this check
        throw new Error(`Invalid theme configuration for ng-chat. "${this.theme}" is not a valid theme value.`);
      }
    }

    // Sends a request to load the friends list
    private fetchFriendsList(isBootstrapping: boolean): void {
      this.adapter.listFriends()
        .pipe(
          map((participantsResponse: ParticipantResponse[]) => {
            this.participantsResponse = participantsResponse;
            this.participants = participantsResponse.map((response: ParticipantResponse) => {
              return response.participant;
            });
          })
        ).subscribe(() => {
          if (isBootstrapping) {
            setTimeout(() => {
              this.getUnseenMessagesCount();
            }, 300);
            //this.restoreWindowsState();
          }
        });
    }

    private async getUnseenMessagesCount() {
      await this.adapter.getUnseenMessagesCount().pipe(
        switchMap(async (result: any[]) => {
          result.forEach((x, index) => {
            if (index == 0) {
              this.openParticipantOnCollapse = x._id;
            }
            const participant = this.participantsResponse.find(el => el.participant.id == x._id);
            participant.metadata.totalUnreadMessages = x.count;
          });
          this.getTotalMessages();
        })
      ).subscribe();
    }

    async fetchMessageHistory(window: Window) {
      // Not ideal but will keep this until we decide if we are shipping pagination with the default adapter
      if (this.adapter instanceof PagedHistoryChatAdapter) {
        window.isLoadingHistory = true;
        var result = await this.adapter.getMessageHistoryByPage(window.participant.id, this.historyPageSize, ++window.historyPage);
        const userNames = result.data.userNames;
        result = result.data.result;
        for (let i = 0; i < result.length; i++) {
          this.assertMessageType(result[i]);
          await this.mapMessage(result[i]);
          result[i].seenBy = [];
          result[i].userID.forEach((userID: any) => {

            if (userID != this.userId) {
              const findUser = userNames.find(u => u.id == userID);
              //if (findUser && !x.seenBy.includes(findUser)) {
                result[i].seenBy.push(findUser);
              //}
            }
          });
          window.messages.unshift(result[i]);
        }
        //window.messages = result.concat(window.messages);
        window.isLoadingHistory = false;
        const direction: ScrollDirection = (window.historyPage == 1) ? ScrollDirection.Bottom : ScrollDirection.None;
        window.hasMoreMessages = result.length == this.historyPageSize;
        this.changeDetRef.detectChanges();
        
        await new Promise((resolve) => {// promise will be returned and used with async/await function

          setTimeout(async () => {
            await this.onFetchMessageHistoryLoaded(result, window, direction, false);
            resolve(true); // when time will pass, than resolve promise
          }); // set wait time in miliseconds
      
        });

          // .pipe(
          //   switchMap(async (result: any) => {
         
          //   })
          // ).subscribe();
      }
      else {
        this.adapter.getMessageHistory(window.participant.id)
          .pipe(
            map((result: Message[]) => {
              result.forEach((message) => this.assertMessageType(message));

              window.messages = result.concat(window.messages);
              window.isLoadingHistory = false;

              setTimeout(() => this.onFetchMessageHistoryLoaded(result, window, ScrollDirection.Bottom));
            })
          ).subscribe();
      }
    }

    private async onFetchMessageHistoryLoaded(messages: Message[], window: Window, direction: ScrollDirection, forceMarkMessagesAsSeen = false): Promise<void> {
      await this.scrollChatWindow(window, direction);
      // if (window.hasFocus || forceMarkMessagesAsSeen) {
      //   const unseenMessages = messages.filter(m => !m.dateSeen && m.fromId != this.userId);
      //   this.markMessagesAsRead(unseenMessages);
      // }
    }

    // Updates the friends list via the event handler
    private onFriendsListChanged(participantsResponse: ParticipantResponse[]): void {
      const getUnseenMessages = this.participantsResponse.length == 0 ? true : false;
      if (participantsResponse) {
        this.participantsResponse = participantsResponse.map((response: ParticipantResponse) => {
          const findCurrentParticipant = this.participantsResponse.find(x => x.participant.id == response.participant.id);
          if (findCurrentParticipant) {
            response.metadata.totalUnreadMessages = findCurrentParticipant.metadata.totalUnreadMessages;
          }
          return response;
        });

        this.participants = participantsResponse.map((response: ParticipantResponse) => {
          return response.participant;
        });

        this.participantsInteractedWith = [];
        if (getUnseenMessages) {
          this.getUnseenMessagesCount();
        }
      }
    }

    // Updates the friends list via the event handler
    private updateTotalUnreadMessages(participantsResponse: ParticipantResponse[]): void {
      if (participantsResponse) {
        this.participantsResponse = participantsResponse;

        this.participants = participantsResponse.map((response: ParticipantResponse) => {
          return response.participant;
        });

        this.participantsInteractedWith = [];
      }
    }
    // Handles seen messages by the adapter
    private async onMessageSeen(message) {
      const openedWindow = this.getOpenWindow();
      if (openedWindow) {
        if (openedWindow.participant.id == message.data.medicalCenterID) {
          const findMessage = openedWindow.messages.find(x => x._id == message.data.messageID);
          if (findMessage) {
            if (!findMessage.seenBy) {
              findMessage.seenBy = [];
              findMessage.userID = [];
            }
            const seenBy = {firstName: message.data.seenFirstName, lastName: message.data.seenLastName};
            if (!findMessage.userID.includes(message.data.userID)) {
              findMessage.userID.push(message.data.userID);
              findMessage.seenBy.push(seenBy);
            }
          }
        }
      }
    }

    // Handles discoonect socket by the adapter

    private onDisconnect() {
      this.onFriendsListChanged([]);
      this.closeAllWindows();
    }
    // Handles received messages by the adapter
    private async onMessageReceived(participant: IChatParticipant, message) {
      if (participant == null) {
        participant = this.findParticipantByMessage(message);
      }
      if (participant && message) {
        const openChatWindow = environment.environment != 'serbia' && message.fromId != this.userId;
        if (openChatWindow) {
          this.setFullScreen();
        }

        const participantResponse = this.participantsResponse.find(x => x.participant.id == participant.id);
        if (participantResponse && message.fromId != this.userId) {
          participantResponse.metadata.totalUnreadMessages++;
          this.readyToMarkAsSeen = false;
          this.changeDetRef.detectChanges();
          this.emitNewMessageSound();
        }
        const openedWindow = this.windows.find(x => x.participant.id == participant.id);
        this.isCollapsed = !openChatWindow && this.isCollapsed;
        if (openedWindow || openChatWindow) {
          const chatWindow = await this.openChatWindow(participant);
          
          if (!chatWindow[1]) {
            await this.mapMessage(message);
            this.assertMessageType(message);
            // if (!chatWindow[1] || !this.historyEnabled) {
            chatWindow[0].messages.push(message);
            this.scrollChatWindow(chatWindow[0], ScrollDirection.Bottom);

            if (chatWindow[0].hasFocus && message.fromId != this.userId) {
              if (participantResponse.metadata.totalUnreadMessages > 0) {
                participantResponse.metadata.totalUnreadMessages--;
              }
              // this.markMessagesAsRead([message]);
              // this.onMessagesSeen.emit([message]);
            }
            //if (!message.manualVitalAnalysisAlertID) {
            //this.emitMessageSound(chatWindow[0]);
            //}
            // Github issue #58
            // Do not push browser notifications with message content for privacy purposes if the 'maximizeWindowOnNewMessage' setting is off and this is a new chat window.
            if (this.maximizeWindowOnNewMessage || (!chatWindow[1] && !chatWindow[0].isCollapsed)) {
              // Some messages are not pushed because they are loaded by fetching the history hence why we supply the message here
              this.emitBrowserNotification(chatWindow[0], message);
            }
    
            // } 
          } 
          else {
            this.scrollChatWindow(chatWindow[0], ScrollDirection.Bottom);
          }
        }
        // else {
        //   if (this.participantMedicalCenterID == message.toId || this.participantMedicalCenterID == message.fromMedCenterID) {
        //     this.openChatWindow(participant, true, true);
        //   }
        //}
        this.getTotalMessages();
        //this.setParticipantsResponseToLocalStorage(this.participantsResponse);
      }
    }

    private findParticipantByMessage(message) {
      if (message.fromMedCenterID == this.userMedicalCenterID || message.toId == this.userMedicalCenterID) {
        if (message.fromMedCenterID == this.userMedicalCenterID ) {
          return this.participants.find(x => x.id == message.toId);
        } else {
          return this.participants.find(x => x.id == message.fromMedCenterID);
        }
      }
      return null;
    }

    async mapMessage(message) {
      if (message.manualVitalAnalysisAlertID) {
        await this.mapAlertMessage(message);
      } else if (message.type == MessageType.Image) {
        message.message = this.translateService.instant('Patient') + ' :' + message.message;
        if (message.hasOwnProperty('pathologyID')) {
          message.pathologyName =  this.ekgAnnotationService.getAnnotaionByID(message.pathologyID);
        }
      } else {
        if (message.message && message.fromMedCenterID != this.userMedicalCenterID && message.lang != this.lang) {
          const cachedMessage = this.cachedMessages.getChatMessage(message._id);
          if (cachedMessage) {
            message.message = cachedMessage.message;
          } else {
            message.message = await this.translateMessage(message.message, message.lang);
            this.cachedMessages.addChatMessage(message);     
          }
        }
      }
      return message;
    }

    async translateMessage(text, fromLang) {
      const data = {text: text, fromLang: fromLang};
      const resp = await this.apiService.translateExternalApi(data).toPromise();
      if (resp.success == true) {
        return resp.data;
      } else {
        return text;
      }
    }

    doubleClick() {
      window.open('/chat', '_blank');
    }

    async onParticipantClickedFromFriendsList(participant: IChatParticipant): Promise<void> {
      await this.openChatWindow(participant, true, true);
    }

    private cancelOptionPrompt(): void {
      if (this.currentActiveOption) {
        this.currentActiveOption.isActive = false;
        this.currentActiveOption = null;
      }
    }

    onOptionPromptCanceled(): void {
      this.cancelOptionPrompt();
    }

    onOptionPromptConfirmed(event: any): void {
      // For now this is fine as there is only one option available. Introduce option types and type checking if a new option is added.
      this.confirmNewGroup(event);

      // Canceling current state
      this.cancelOptionPrompt();
    }

    private confirmNewGroup(users: User[]): void {
      const newGroup = new Group(users);

      this.openChatWindow(newGroup);

      if (this.groupAdapter) {
        this.groupAdapter.groupCreated(newGroup);
      }
    }

    // Opens a new chat whindow. Takes care of available viewport
    // Works for opening a chat window for an user or for a group
    // Returns => [Window: Window object reference, boolean: Indicates if this window is a new chat window]
    private async openChatWindow(participant: IChatParticipant, focusOnNewWindow = false, invokedByUserClick = false): Promise<[Window, boolean]> {
      // Is this window opened?
      const openedWindow = this.windows.find(x => x.participant.id == participant.id);
      //if (invokedByUserClick) {
        this.windows.map(a => a.isOpen = false);
      //}
      const collapseWindow = invokedByUserClick ? false : !this.maximizeWindowOnNewMessage;
      if (!openedWindow) {
        if (invokedByUserClick) {
          this.onParticipantClicked.emit(participant);
        }
        // Refer to issue #58 on Github
        const newChatWindow: Window = new Window(participant, this.historyEnabled, collapseWindow, this.openWindowOnNewMessage);
        this.windows.unshift(newChatWindow);
        // Loads the chat history via an RxJs Observable
        if (this.historyEnabled) {
          await this.fetchMessageHistory(newChatWindow);
        }

        // Is there enough space left in the view port ? but should be displayed in mobile if option is enabled
        if (!this.isViewportOnMobileEnabled) {
          if (this.windows.length * this.windowSizeFactor >= this.viewPortTotalArea - (!this.hideFriendsList ? this.friendsListWidth : 0)) {
            this.windows.pop();
          }
        }

        this.updateWindowsState(this.windows);
        if (focusOnNewWindow && !collapseWindow) {
          this.focusOnWindow(newChatWindow);
        }

        this.participantsInteractedWith.push(participant);
        this.onParticipantChatOpened.emit(participant);
        newChatWindow.isOpen = invokedByUserClick ? true : newChatWindow.isOpen;
        if (newChatWindow.isOpen) {
          this.setActiveParticipant(participant);
        }
        return [newChatWindow, true];
      }
      else {
        // Returns the existing chat window
        openedWindow.isOpen = true;
        this.setActiveParticipant(participant);
        if (invokedByUserClick) {
          this.readyToMarkAsSeen = true;
        }
        return [openedWindow, false];
      }
    }

    setActiveParticipant(participant) {
      this.participants.forEach(x => {
        x.open = false;
        if (x.id == participant.id) {
          x.open = true;
        }
      });
    }

    getOpenWindow() {
      return this.windows.find(x => x.isOpen == true);
    }

    // Focus on the input element of the supplied window
    private focusOnWindow(window: Window, callback: Function = () => { }): void {
      const windowIndex = this.windows.indexOf(window);
      if (windowIndex >= 0) {
        setTimeout(() => {
          if (this.chatWindows) {
            const chatWindowToFocus = this.chatWindows.toArray()[windowIndex];
            //chatWindowToFocus.markMessagesAsRead(window.messages);
            chatWindowToFocus.chatWindowInput.nativeElement.focus();
          }
          callback();
        });
      }
    }

    private assertMessageType(message: Message): void {
      // Always fallback to "Text" messages to avoid rendenring issues
      if (!message.type) {
        message.type = MessageType.Text;
      }
    }

    // Marks all messages provided as read with the current time.
    markMessagesAsRead(messages: Message[]): void {
      if (window.focus) {
        messages = messages.filter(x => x.fromId != this.userId);
        if (messages.length > 0) {
          const currentDate = new Date();
          messages.forEach((msg) => {
            msg.dateSeen = currentDate;
          });
          var participantResponse;
          if (messages[0].fromMedCenterID == this.userMedicalCenterID) {
            participantResponse = this.participantsResponse.find(x => x.participant.id == messages[0].toId);
          } else {
            participantResponse = this.participantsResponse.find(x => x.participant.id == messages[0].fromMedCenterID);
          }
          
          if (participantResponse && participantResponse.metadata.totalUnreadMessages > 0) {
            participantResponse.metadata.totalUnreadMessages = participantResponse.metadata.totalUnreadMessages - messages.length;
            this.setParticipantsResponseToLocalStorage(this.participantsResponse);
            this.getTotalMessages();
          }
          this.onMessagesSeen.emit(messages);
          this.adapter.onMessagesSeen(messages);
        }
      }
    }

    // Buffers audio file (For component's bootstrapping)
    private bufferAudioFile(): void {
      if (this.audioSource && this.audioSource.length > 0) {
        this.audioFile = new Audio();
        this.audioFile.src = this.audioSource;
        this.audioFile.load();
      }
    }

    // Emits a message notification audio if enabled after every message received
    private emitMessageSound(window: Window): void {
      if (this.audioEnabled && !window.hasFocus && this.audioFile) {
        this.audioFile.play();
      }
    }
    
    private emitNewMessageSound(): void {
      if (this.audioEnabled && this.audioFile) {
        this.audioFile.pause();
        this.audioFile.currentTime = 0;
        this.audioFile.volume = this.chatVolume;
        this.audioFile.play();
      }
    }

    // Emits a browser notification
    private emitBrowserNotification(window: Window, message: Message): void {
      if (this.browserNotificationsBootstrapped && !window.hasFocus && message) {
        const notification = new Notification(`${this.localization.browserNotificationTitle} ${window.participant.displayName}`, {
          'body': message.message,
          'icon': this.browserNotificationIconSource
        });

        setTimeout(() => {
          notification.close();
        }, message.message.length <= 50 ? 5000 : 7000); // More time to read longer messages
      }
    }

    // Saves current windows state into local storage if persistence is enabled
    private updateWindowsState(windows: Window[]): void {
      if (this.persistWindowsState) {
        const participantIds = windows.map((w) => {
          return w.participant.id;
        });

        localStorage.setItem(this.localStorageKey, JSON.stringify(participantIds));
      }
    }

    private restoreWindowsState(): void {
      try {
        if (this.persistWindowsState) {
          const stringfiedParticipantIds = localStorage.getItem(this.localStorageKey);

          if (stringfiedParticipantIds && stringfiedParticipantIds.length > 0) {
            const participantIds = <number[]>JSON.parse(stringfiedParticipantIds);

            const participantsToRestore = this.participants.filter(u => participantIds.indexOf(u.id) >= 0);

            participantsToRestore.forEach((participant) => {
              this.openChatWindow(participant);
            });
          }
        }
      }
      catch (ex) {
        console.error(`An error occurred while restoring ng-chat windows state. Details: ${ex}`);
      }
    }

    // Gets closest open window if any. Most recent opened has priority (Right)
    private getClosestWindow(window: Window): Window | undefined {
      const index = this.windows.indexOf(window);

      if (index > 0) {
        return this.windows[index - 1];
      }
      else if (index == 0 && this.windows.length > 1) {
        return this.windows[index + 1];
      }
    }

    private closeWindow(window: Window): void {
      const index = this.windows.indexOf(window);

      this.windows.splice(index, 1);

      this.updateWindowsState(this.windows);
      this.onParticipantChatClosed.emit(window.participant);
    }

    private getChatWindowComponentInstance(targetWindow: Window): NgChatWindowComponent | null {
      const windowIndex = this.windows.indexOf(targetWindow);
      if (this.chatWindows) {
        const targetWindow = this.chatWindows.toArray()[windowIndex];

        return targetWindow;
      }

      return null;
    }

    // Scrolls a chat window message flow to the bottom
    private async scrollChatWindow(window: Window, direction: ScrollDirection): Promise<void> {

      const chatWindow = this.getChatWindowComponentInstance(window);
      if (chatWindow) {
        await chatWindow.scrollChatWindow(window, direction);
      }
    }

    closeAllWindows() {
      this.windows.forEach(x => {
        this.closeWindow(x);
      });
    }

    onWindowMessagesSeen(messagesSeen: Message[]): void {
      this.markMessagesAsRead(messagesSeen);
      this.getTotalMessages();
    }

    onWindowChatClosed(payload: { closedWindow: Window; closedViaEscapeKey: boolean }): void {
      const { closedWindow, closedViaEscapeKey } = payload;

      if (closedViaEscapeKey) {
        const closestWindow = this.getClosestWindow(closedWindow);

        if (closestWindow) {
          this.focusOnWindow(closestWindow, () => { this.closeWindow(closedWindow); });
        }
        else {
          this.closeWindow(closedWindow);
        }
      }
      else {
        this.closeWindow(closedWindow);
      }
    }

    async onLoadHistoryTriggered(event){
      await this.fetchMessageHistory(event.window);
      event.resp(true)  ;
    }

    onWindowTabTriggered(payload: { triggeringWindow: Window; shiftKeyPressed: boolean }): void {
      const { triggeringWindow, shiftKeyPressed } = payload;

      const currentWindowIndex = this.windows.indexOf(triggeringWindow);
      let windowToFocus = this.windows[currentWindowIndex + (shiftKeyPressed ? 1 : -1)]; // Goes back on shift + tab

      if (!windowToFocus) {
        // Edge windows, go to start or end
        windowToFocus = this.windows[currentWindowIndex > 0 ? 0 : this.chatWindows.length - 1];
      }

      this.focusOnWindow(windowToFocus);
    }

    async onWindowMessageSent(messageSent: any) {
      const response = await this.adapter.sendMessage(messageSent.message);
      messageSent.resp(response);
    }

    async onWindowMessageHide(messageSent: any) {
      const response = await this.adapter.hideMessage(messageSent.message);
      messageSent.resp(response);
    }

    async onWindowMessageDelete(messageSent: any) {
      const response = await this.adapter.deleteMessage(messageSent.message);
      messageSent.resp(response);
    }

    async onWindowMessageUnHide(data: any) {
      const response = await this.adapter.unHideMessage(data.message);
      data.resp(response);
    }

    onWindowOptionTriggered(option: IChatOption): void {
      this.currentActiveOption = option;
    }

    triggerOpenChatWindow(user: User): void {
      if (user) {
        this.openChatWindow(user);
      }
    }

    triggerCloseChatWindow(userId: any): void {
      const openedWindow = this.windows.find(x => x.participant.id == userId);

      if (openedWindow) {
        this.closeWindow(openedWindow);
      }
    }

    triggerToggleChatWindowVisibility(userId: any): void {
      const openedWindow = this.windows.find(x => x.participant.id == userId);

      if (openedWindow) {
        const chatWindow = this.getChatWindowComponentInstance(openedWindow);

        if (chatWindow) {
          chatWindow.onChatWindowClicked(openedWindow);
        }
      }
    }

    private handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        const openedWindow = this.getOpenWindow();
        if (openedWindow) {
          this.closeWindow(openedWindow);
          this.openChatWindow(openedWindow.participant, false, true);
        }
      }
    }

    onResize(ev) {
      if (ev.contentRect.width > 600) {
        if (this.fullChat == false) {
          this.fullChat = true;
          this.changeDetRef.detectChanges();
        }
        const width = this.myIdentifier.nativeElement.style.width.replace('px', '');
        if (width) {
          this.myIdentifier.nativeElement.style.fontSize = (12 + width * 0.0115) + 'px';
        }
      } else {
        if (this.fullChat == true) {
          this.fullChat = false;
          this.changeDetRef.detectChanges();
        }
        this.myIdentifier.nativeElement.style.fontSize = null;
      }
    }

    setFullScreen() {
      this.fullScreen = true;
      this.fullChat = true;
      this.myIdentifier.nativeElement.style.height = (Math.max( window.innerHeight, document.body.clientHeight) - 65) + 'px';
      const width = Math.max( window.innerWidth, document.body.clientWidth);
      this.myIdentifier.nativeElement.style.width = (width - 25) + 'px';
      this.changeDetRef.detectChanges();
    }

    setSmallScreen() {
      this.fullScreen = false;
      this.fullChat = false;
      this.myIdentifier.nativeElement.style.height = null;
      this.myIdentifier.nativeElement.style.width = null;
      this.changeDetRef.detectChanges();
    }

    toggleFullScreen() {
      if (this.fullScreen) {
        this.setSmallScreen();
      } else {
        this.setFullScreen();
      }

    }
    
    processMessageFromAndroid(data) {
      this.isCollapsed = false;
      const participant = this.findParticipantByMessage(data);
      if (participant) {
        this.openChatWindow(this.findParticipantByMessage(data));
      }
    }

    getTheme(): void {
      if (this.localStorage.retrieve('darkTheme') == true) {
        this.theme = Theme.Dark;
      } else {
        this.theme = Theme.Light;
      }
    }
    
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    collapse() {
      if (this.isCollapsed) {
        window.history.pushState('open-chat', document.title, window.location.href);
        window.history.pushState('open-chat', document.title, window.location.href);
        if (this.totalMessages > 0) {
          if (this.openParticipantOnCollapse) {
            const participant = this.participants.find(x => x.id == this.openParticipantOnCollapse);
            this.openChatWindow(participant);
            this.openParticipantOnCollapse = null;
          } else {
            const participant = null;
            //count participants with unread messages
            const participantsWithUnreadMessages = this.participantsResponse.filter(x => x.metadata.totalUnreadMessages > 0);
            if (participantsWithUnreadMessages.length == 1) {
              const participant = this.participants.find(x => x.id == participantsWithUnreadMessages[0].participant.id);
              if (participant) {
                this.openChatWindow(participant);
              }
            }
          }

        }
      }
      this.isCollapsed = !this.isCollapsed;
    }
    async mapAlertMessage(message) {
      const cachedMessage = this.cachedMessages.getChatMessage(message._id);
      if (cachedMessage) {
        message.color = cachedMessage.color;
        message.message = cachedMessage.message;
      } else {
        const data = await this.apiService.getAlertData(message.manualVitalAnalysisAlertID).toPromise();
        const alertData = data.data;
        if (alertData.VitalAnalysisAlertUrgentAlertTemplateID > 0) {
          const urgentAlertData = await this.urgentTemplateService.getTemplates().toPromise();
          const alertTemplate = urgentAlertData.find(x => x.UrgentAlertTemplateID == alertData.VitalAnalysisAlertUrgentAlertTemplateID);
          if (alertTemplate) {
            const translate = message.fromMedCenterID != this.userMedicalCenterID && message.lang != this.lang && !['en', 'bg'].includes(this.lang);
            const visit = await this.apiService.getVisit(alertData.VitalAnalysisAlertVisitID).toPromise();
            const replaceValue = '%v%';
            let messageText = alertTemplate.UrgentAlertTemplateTranslationName;
            alertData.VitalAnalysisAlertValues.forEach(item => {
              messageText = messageText.replace(replaceValue, item.VitalAnalysisAlertValue);
            });
            if (translate && this.lang != 'sl') {
              messageText = await this.translateMessage(messageText, message.lang);        
            }
            const level = Math.abs(alertData.VitalAnalysisAlertLevel);
            message.color = (alertTemplate.UrgentAlertTemplateType == 'TECH') ? 'blue-txt' : getColorByLevel(level) + '-txt';
            message.message = '<strong>' + this.translateService.instant('Patient') + ': ' + visit.data.PatientNames + '</strong><br/> - ' + messageText;
            if (message.sendAction == true) {
              const recommendedAction = '<br/>' + this.translateService.instant('Recommended action') + ':<br/>';
              var action = alertTemplate.UrgentAlertTemplateTranslationAction;
              if (translate) {
                action = await this.translateMessage(action, message.lang);        
              }
              message.message += '<span>' + recommendedAction + '</span><strong>' + action + '<strong>';
            }
            message.message = message.message.replace(/\n/g, '<br>');
          }
        } else if (alertData.VitalAnalysisAlertType == 'NEWS') {
          message.message = this.newsAnnotationService.getAnnotaionByID(alertData.VitalAnalysisAlertNewsAnnotationID);
        } else {
          message.message = this.ekgAnnotationService.getAnnotaionByID(alertData.VitalAnalysisAlertEkgAnnotaionID);
        }
        this.cachedMessages.addChatMessage(message);
      }
      return message;
    }

}
