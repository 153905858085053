<div *ngIf="shouldDisplay" id="ng-chat-people" [ngClass]="{'primary-outline-color': true, 'primary-background': true, 'ng-chat-people-collapsed': isCollapsed}">
	<div class="ng-chat-title secondary-background" (click)="toggleFullScreen()">
		<div class="icon-wrapper" title="{{userFirstName}} {{userLastName}}">
			<div class="avatar current">
				{{userFirstName | slice:0:1}}{{userLastName | slice:0:1}}
			</div>
		</div>
		<span class="ng-chat-messages-count" *ngIf="totalMessages > 0">
			{{ 'unread messages' | translate : { element: totalMessages} }}
		</span>
		<span class="ng-chat-messages-count" *ngIf="totalMessages == 0">
			{{ 'No messages found' | translate }}
		</span>
		<app-ng-chat-volume></app-ng-chat-volume>
	</div>
	<div *ngIf="currentActiveOption" class="ng-chat-people-actions" (click)="onFriendsListActionCancelClicked()">
		<a href="javascript:void(0);" class="ng-chat-people-action">
			<i class="remove-icon"></i>
		</a>
		<a href="javascript:void(0);" class="ng-chat-people-action" (click)="onFriendsListActionConfirmClicked()">
			<i class="check-icon"></i>
		</a>
	</div>
	<div class="ng-chat-search_friend-cont">
		<input *ngIf="searchEnabled" id="ng-chat-search_friend" class="friends-search-bar" type="search" 
			style="font-family: FontAwesome, Arial; font-style: normal"
			[placeholder]="'&#xF002; ' +localization.searchPlaceholder" 
			[(ngModel)]="searchInput" />
	</div>
	<ul id="ng-chat-users" *ngIf="!isCollapsed" class="disable-scrollbars" [ngClass]="{'offset-search': searchEnabled}">
		<li *ngFor="let user of filteredParticipants" [ngClass]="{'active': user.open}">
			<input 
				*ngIf="currentActiveOption && currentActiveOption.validateContext(user)" 
				type="checkbox" 
				class="ng-chat-users-checkbox" 
				(change)="onFriendsListCheckboxChange(user, $event.target.checked)" 
				[checked]="isUserSelectedFromFriendsList(user)"/>
			<div [ngClass]="{'ng-chat-friends-list-selectable-offset': currentActiveOption, 'ng-chat-friends-list-container': true}" (click)="onUserClick(user)">
				<span [ngClass]="{'ng-chat-participant-status': true, 'online': user.status == ChatParticipantStatus.Online, 'busy': user.status == ChatParticipantStatus.Busy, 'away': user.status == ChatParticipantStatus.Away, 'offline': user.status == ChatParticipantStatus.Offline}" title="{{chatParticipantStatusDescriptor(user.status, localization)}}"></span>
				<div *ngIf="!user.avatar" class="icon-wrapper">
					<i class="user-icon"></i>
				</div>
				<img *ngIf="user.avatar" alt="" class="avatar" height="30" width="30"  [src]="user.avatar | sanitize"/>
				<strong title="{{user.displayName}}">{{user.displayName}}</strong>
				<span *ngIf="unreadMessagesTotalByParticipant(user).length > 0" class="ng-chat-unread-messages-count unread-messages-counter-container primary-text">{{unreadMessagesTotalByParticipant(user)}}</span>
			</div>
		</li>
	</ul>
</div>