import { FormGroup } from '@angular/forms';

export class RegistrationValidator {
    static validate(passwordFormGroup: FormGroup) {
        const password = passwordFormGroup.controls.NewPassword.value;
        const confirmPassword = passwordFormGroup.controls.PasswordRepeat.value;

        if (confirmPassword !== password) {
            return {
                doesMatchPassword: true
            };
        }
        return null;

    }
}
