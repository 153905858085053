import { AuthService } from './../services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, HostListener, Injector } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { FormControl } from '@angular/forms';

import { environment } from 'src/environments/environment';
import { HeadingService } from '../services/heading.service';
import { ApiService } from '../services/api.service';
import { RightsService } from '../services/rights.service';
import { Subscription } from 'rxjs';
import { BaseLayoutComponent } from './base-layout/base-layout.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseLayoutComponent implements OnInit, OnDestroy {
    /*!!!set to true if window max-width <= 991px*/
    public hideSidebar = false;
    public showMedicalCenterAlertSettings = false;
    public heading = '';
    public logo = 'assets/images/left-side-bar/CheckPointCardio-logo.png';
    public defaultImage = '/assets/images/left-side-bar/profile-picture.png';
    public avatarImage;
    public medicalCenter: any;
    private liveReloadLoaded = false;
    public isLoggedUserDoctor = false;
    public doctorUserID = null;
    private subscriptions: Array<Subscription> = [];
    private sessionData = {
      'session': {
        'loggedUser': null,
        'token': ''
      }
    };

    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.checkHideSidebar();
    }
    constructor(
        private heading$: HeadingService,
        private api$: ApiService,
        private router: Router,
        private authService: AuthService,
        public rights: RightsService,
        injector: Injector
    ) {
      super(injector);
      const loggedUser = this.localStorage.retrieve('loggedUser');
      this.isLoggedUserDoctor = loggedUser.UserType === 'medical_stuff' && loggedUser.object.MedicalStaffTypeID === '00401';
      if (this.isLoggedUserDoctor) {
        this.doctorUserID = loggedUser.UserID;
      }
      if (this.api$.constructBr == 0) {
        document.querySelectorAll('head > style')[1].remove();
      }
      this.api$.constructBr++;
      this.subscriptions.push(this.heading$.title.subscribe((t) => {
        this.heading = this.heading$.getTitleTranslatedText(t);
      }));
      this.subscriptions.push(this.medicalCenterService.medicalCenterID.subscribe((id) => {
        this.medicalCenter = new FormControl(id);
      }));

      if (environment.brc) {
        this.logo = '/assets/images/left-side-bar/logo_brc.png';
      }
    }

    ngOnInit() {
      this.medicalCenter = new FormControl(this.medicalCenterService.getMedicalCenterID());
      this.avatarImage = (this.user.UserImage) ? environment.protocol + environment.imgUrl + this.user.UserImage : this.defaultImage;
      if (!this.liveReloadLoaded && environment.liveReload) {
        this.liveReloadLoaded = true;
        const script = window.document.createElement('script');
        script.src = 'http://localhost:35729/livereload.js?snipver=1';
        window.document.head.appendChild(script);
      }
      this.checkHideSidebar();
    }

    editProfile() {
      this.router.navigateByUrl('/users/edit-profile').then();
    }

    changeMedicalCenter(event: number) {
      this.medicalCenterService.setMedicalCenterID(event);
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }
    
    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    exit() {
      this.sessionData.session.loggedUser = this.localStorage.retrieve('loggedUser');
      this.sessionData.session.token = this.localStorage.retrieve('token');

      this.api$.doLogoutAut(this.sessionData).subscribe(
        (data) => {
          if (data?.success !== true) {
            alert(data?.data?.message);
          } else {
            this.authService.logout();
            this.router.navigate(['/login']).then();
          }
        },
        error => {
          alert('The session has NOT been deleted successfully!' + error?.statusText);
        }
      );
    }

    private checkHideSidebar() {
      this.hideSidebar = (window.innerWidth <= 991) ? true : false;
    }
}