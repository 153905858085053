<div class="login-wrap">
    <img class="login-top-image" src="assets/images/logo.svg">
    <img class="login-slogan" src="assets/images/login-saving-lives.png">
	<form class="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="forgot-password-title">Forgot your password?</div>
		<ng-template [ngIf]="!success" [ngIfElse]="successSend">
			<div class="login-input-wrap login-text">
                <input placeholder="{{'username' | translate}}" type="text" id="inputEmail" formControlName="username" class="login-input" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autofocus>
                <div class="login-error" *ngIf="submitted && f.username.errors?.required" translate>Username is required</div>
                <div class="login-error" *ngIf="errorMessageFromServer" translate>{{errorMessageFromServer}}</div>
            </div>
			<br/>
			<div class="login-input-wrap login-button">
				<button *ngIf="!loading" class="login-input" [disabled]="loading" type="submit" translate>Send</button>
                <button *ngIf="loading" type="button" class="login-input" translate><i class="fas fa-spinner fa-spin"></i></button>
            </div>
		</ng-template>
		<ng-template #successSend>
			<div class="login-register-title" translate>Message has been send</div>
			<div class="submessage-text">
				<span class="submessage-text" translate>
					We send an message to {{sendToEmail}}. Open it and read the instraction how to change your password!
				</span>
			</div>
		</ng-template>
	</form>
</div>