export const environment = {
  environment: 'beta',
  production: false,
  brc: false,
  bookingAPI: 'd.infn.dev/myki-cardio-calendar-backend',
  apiUrl: 'beta.cpcardio.com/api',
  apiVitalUrl: 'beta.cpcardio.com',
  apiVer2URL: 'beta.cpcardio.com/api/v2',
  imgUrl: 'beta.cpcardio.com/api',
  hmr: false,
  protocol: 'https://',
  nodeServer: 'beta.cpcardio.com:8005',
  videoCallApi: 'https://wb.daskal.eu/api/v2/',
  videoCallWs: 'wss://wb.daskal.eu/r',
  liveReload: true,
  firebase: {
    apiKey: 'AIzaSyAAw2wr3ZB8Z-RdRWMRfce01CcOJLUUx1Q',
    authDomain: 'online-cpcardio.firebaseapp.com',
    projectId: 'online-cpcardio',
    storageBucket: 'online-cpcardio.appspot.com',
    messagingSenderId: '449681999364',
    appId: '1:449681999364:web:f70600ebd5f888aeead751',
    measurementId: 'G-5ZR9Y27Z1C'
  },
  googleMaps: {
    apiKey: 'AIzaSyBeUda-v3sWK8d8JvmBdlEmWHpldE0elc4',
  },
  recaptchaV2: {
    siteKey: '6LeLFYYcAAAAAHfow-fQvgfepDplq1So8_sfqqUJ'
  },
  defaultMedicalCenterPatientData: {
    '53': {
      'deviceOption': 1,
      'visitSpoSensorType': '01202',
      'visitSpoScaleType': 1,
      'temperature': 0,
      'showMentalState': false
    },
  },
  useRecaptcha: false,
  boichevSignature: true,
  useChat: {
    id: 1,
    name: 'Checkpoint Cardio Center'
  },
  defaultTimeZone: null,
  reorderMbpOnIcuByMedCenter: [50, 55 , 56, 58, 60],
  showGDSOnICUIndividual: true,
};
