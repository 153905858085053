import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AndroidAppInterface } from '../interfaces/android-app-interface';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(public auth: AuthService, public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.auth.isAuthenticated()) {
            if (route.data.permissions) {
                const userPermissions = this.auth.getPermissions();
                return route.data.permissions.some(
                    (permission: string) => userPermissions.indexOf(permission) !== -1);
            }
            return true;
        }

        const mobileAppObj = (window as any).ANDROID_OBJ as AndroidAppInterface
        if (mobileAppObj) {
            this.router.navigate(['/screen/nurses-login']).then();
        } else {
            this.router.navigate(['login']).then();
        }
        return false;
    }
}
