import { PurposeService } from './../services/purpose.service';
import { CountryService } from './../services/country.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { RiskRateService } from '../services/risk-rate.service';
import { MedicalCodeService } from './../services/medicalCodes.service';
import { EkgAnnotationService } from '../services/ekg-annotation-service';
import { NewsAnnotationService } from '../services/news-annotation-service';
import { UrgentAlertTemplateService } from '../services/urgent-alert-template-service';

@Injectable()
export class InitResolver implements Resolve<any> {

    constructor(
        private riskRateService: RiskRateService, 
        private medicalCodeService: MedicalCodeService,
        private countryService: CountryService,
        private purposeService: PurposeService,
        private ekgAnnotService: EkgAnnotationService,
        private newsAnnotService: NewsAnnotationService,
        private urgentAlertTemplateService: UrgentAlertTemplateService,
        ) {
    }
    resolve(): Observable<any> | Promise<any> | any {
        this.purposeService.getPurposes();
        this.medicalCodeService.getMedicalCodes();
        this.countryService.getCountriesFromApi();
        this.ekgAnnotService.getEkgAnnotations().subscribe();
        this.newsAnnotService.getNewsAnnotations().subscribe();
        this.urgentAlertTemplateService.getTemplates().subscribe();
        return this.riskRateService.getRiskRates();
    }
}

