import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
    providedIn: 'root'
})
export class EkgAnnotationService {

    private data: any;
    constructor(private localStorage: LocalStorageService, private api$: ApiService) {
    }
    public getEkgAnnotations(): Observable<any> {
        const newsAnnotation = this.localStorage.retrieve('ekg-annotations_v2');
        if (!newsAnnotation) {
            return this.api$.getEkgAnnotations().map(data => {
                if (data.success == true) {
                    this.data = data.data;
                    this.localStorage.store('ekg-annotations_v2', data.data);
                    return data.data;
                }

            });
        } else {
            this.data = newsAnnotation;
            return of(newsAnnotation);
        }
    }

    public getAnnotaionByID(id: number) {
        if (!this.data) {
            this.data = this.localStorage.retrieve('ekg-annotations_v2');
        }
        const find = this.data.find(x => x.EkgAnnotationID == id);
        return find? find. EkgAnnotationTranslationName: '';
    }
  
}