/* eslint-disable no-var */
import { IChatParticipant } from './core/chat-participant';
import { ParticipantResponse } from './core/participant-response';
import { IChatGroupAdapter } from './core/chat-group-adapter';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Message } from './core/message';
import { Group } from './core/group';
import { ChatParticipantStatus } from './core/chat-participant-status.enum';
import { PagedHistoryChatAdapter } from './core/paged-history-chat-adapter';

export class DemoAdapter extends PagedHistoryChatAdapter implements IChatGroupAdapter {
    private medicalCenters;
    private socket;
    private medicalCenterID;
    constructor(medicalCenters, socket, medicalCenterID) {
      super();
      this.medicalCenters = medicalCenters;
      this.socket = socket;
      this.medicalCenterID = medicalCenterID;
      this.InitializeSocketListerners();
    }

    listFriends(): Observable<ParticipantResponse[]> {

      return of(this.medicalCenters.map(medicalCenter => {
        const participantResponse = new ParticipantResponse();
        participantResponse.participant = medicalCenter;
        participantResponse.metadata = {
          totalUnreadMessages: 0
        };

        return participantResponse;
      }));
    }

    getMessageHistory(destinataryId: any): Observable<Message[]> {
      return of([]).pipe(delay(2000));
    }

    public getUnseenMessagesCount(): Observable<any[]> {
      var fromMedicalCenterIDs = [];
      this.medicalCenters.forEach(element => {
        fromMedicalCenterIDs.push(element.id);
      });
      const observable = new Observable(observer => {
        this.socket.emit('unseen-messages-by-participant', {fromMedCenterID: fromMedicalCenterIDs, toMedicalCenterID: this.medicalCenterID}, function (callback: any) {
          observer.next(callback);
        });
      }); 
      return observable as Observable<any[]>;
    }

    async getMessageHistoryByPage(participant: IChatParticipant, size: number, page: number)  {
      return await new Promise(resolve => this.socket.emit('find-history', {fromID: participant, toID: this.medicalCenterID, size: size, page: page}, (response) => {
        resolve(response);
      }));
    }

    async sendMessage(message: Message) {

      return await new Promise(resolve => this.socket.emit('message', message, (response) => {
        resolve(response);
      }));

    }

    async hideMessage(data: any) {
      return await new Promise(resolve => this.socket.emit('hide-message', data, (response) => {
        resolve(response);
      }));
    }

    async deleteMessage(data: any) {
      return await new Promise(resolve => this.socket.emit('delete-message', data, (response) => {
        resolve(response);
      }));
    }
    
    async unHideMessage(data: any) {
      return await new Promise(resolve => this.socket.emit('unhide-message', data, (response) => {
        resolve(response);
      }));
    }

    onMessagesSeen(messages: Message[]): void {
      this.socket.emit('messages-seen', {messages: messages});
    }

    groupCreated(group: Group): void {
      this.medicalCenters.push(group);

      this.medicalCenters = this.medicalCenters.sort((first, second) =>
        second.displayName > first.displayName ? -1 : 1
      );

      // Trigger update of friends list
      this.listFriends().subscribe(response => {
        this.onFriendsListChanged(response);
      });
    }

    public InitializeSocketListerners(): void {
      this.socket.connect();
      let _this = this;
      this.socket.on('connect', function () {
        console.log('connect', _this.socket);
      });
      this.socket.on('active-medical-centers', function (data) {
        // Trigger update of friends list
        if (data.success === true) {
          _this.listFriends().subscribe(medCenters => {
            const activeMedCentersID = []; 
            data.data.forEach(element => {
              activeMedCentersID.push(element.medCenterID);
            });
            medCenters.forEach(x => {
              x.participant.status = activeMedCentersID.includes(x.participant.id)? ChatParticipantStatus.Online : ChatParticipantStatus.Offline;
            });

            medCenters.sort((a,b) => a.participant.status - b.participant.status); // b - a for reverse sort
            _this.onFriendsListChanged(medCenters);
          });
        }
      });
      this.socket.on('message', (messageWrapper) => {
        // Handle the received message to ng-chat
        this.onMessageReceived(messageWrapper.user, messageWrapper);
      });
      this.socket.on('seen-message', (messageWrapper) => {
        this.onMessageSeen(messageWrapper);
      });
      this.socket.on('disconnect', (messageWrapper) => {
        this.onDisconnect();
      });
      //   this.socket.on("friendsListChanged", (usersCollection: Array<any>) => {
      //     // Handle the received message to ng-chat
      //   	var currentUser = usersCollection.find(x => x.participant.id == 100);
      //   	if(currentUser) {
      //     	var currentUserDomain = currentUser.participant.domain;

      //   		var admins = usersCollection.filter(x => (x.participant.admin ) && x.participant.domain == currentUserDomain);
      //   		var friendsList = usersCollection.filter(x => (x.participant.admin || x.participant.id == this.userId ) && x.participant.domain == currentUserDomain);
      //   		if(admins.length > 0) {
      //       	this.friendsList = friendsList;
      //       	var user = admins[0].participant;
      //       	this.AdminIsOnlineSource.next(user);

      //   			this.onFriendsListChanged(friendsList);
      //   		}else {
      //       	this.friendsList = friendsList;
      //   			this.AdminIsOnlineSource.next(new User);
      //   			this.onFriendsListChanged(friendsList);
      //   		}
      //   	}else {
      //   		this.friendsList = friendsList;
      //   	}
      //   });

      //   this.socket.on("userDisconnect", (participant) => {
      //     this.onUserDisconnect(participant);
      //   });
      //   this.socket.on("userConnect", (participant) => {
      //     this.onUserConnect(participant);
      //   });
    }
}
