import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { RegistrationValidator } from '../user-form/user-form-tab-common/registerValidator';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: [
    './../../login/login.component.scss',
    './password-reset.component.scss'
  ],
})
export class PasswordResetComponent implements OnInit {

  token;
  resetForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  checkResetToken: boolean = false;
  successfulReset: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
  ) {
    this.resetForm = this.fb.group({
      NewPassword: ['',  [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#$%^&]).{8,}')]],
      PasswordRepeat: ['', Validators.required],
    }, {
      validator: RegistrationValidator.validate.bind(this)
    });
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.params.token;
    this.apiService.checkResetToken({ token: this.token }).subscribe(
      (resp: any) => {
        if (resp.success === true) {
          this.checkResetToken = true;
        } else if (resp.success === false) {
          this.router.navigate(['/forgot-password']).then(() => {
            //this.messagingService.setMessage(resp.data.message, 'error');
          });
        }
      });
  }


  onSubmit(data) {
    if (this.resetForm.invalid) {
      return;
    }
    this.submitted = true;
    data.token = this.token;
    this.loading = true;
    this.apiService.resetPassword(data).subscribe(
      (resp: any) => {
        if (resp.success === true) {
          this.successfulReset = true;
          this.checkResetToken = false;
          // this.router.navigate(['/login']).then(()=>{
          //   this.messagingService.setMessage('New password saved.', 'message');
          // }); 
        } else if (resp.success === false) {
          //this.messagingService.setMessage(resp.data.message, 'error');
        }
        this.loading = false;
      }
    );

  }

  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls; }

}
