
    <div class="alert-settings-backdrop"></div>
    <div class="alert-settings mark-invalid-red" [ngClass]="{'disable': canManageAlertValues == false}" [formGroup]="this.form">
        <div class="alert-settings-in">
            <label class="alert-settings-close" (click)="close()">
                <img src="assets/images/screens/screen-detail/close.svg">
            </label>

            <div class="alert-settings-title" translate>Alarm settings</div>
            <div class="alert-settings-row">
                <div class="alert-settings-row-title"></div>
                <div class="pull-right">
                    <div class="alert-settings-row-input">Red (low)</div>
                    <div class="alert-settings-row-input">Orange (low)</div>
                    <div class="alert-settings-row-input">Yellow (low)</div>
                    <div class="alert-settings-row-input">Yellow (high)</div>
                    <div class="alert-settings-row-input">Orange (high)</div>
                    <div class="alert-settings-row-input">Red (high)</div>
                </div>
            </div>
            <!-- *ngFor="let item of vitalParamsTresholds" -->
            <div class="alert-settings-row"  formArrayName="items" *ngFor="let item of form.get('items')['controls']; let i = index;">
                <div [formGroupName]="i">
                <div class="alert-settings-row-title">
                    <span translate>{{item.value.name}}</span>
                    <span *ngIf="item.value.measureParamID == 10 && (item.value.id == 5 || item.value.parentID == 5)" translate> Scale 1</span>
                    <span *ngIf="item.value.measureParamID == 10 && (item.value.id == 6  || item.value.parentID == 6)" translate> Scale 2</span>
                </div>
                <div class="pull-right">
                    <div class="alert-settings-row-input">
                        <input class="form-control" type="number" (change)="changeData(i, 'redLow')" formControlName="redLow" [attr.disabled]="canManageAlertValues == false ? '' : null">
                    </div>
                    <div class="alert-settings-row-input">
                        <input class="form-control" type="number" (change)="changeData(i, 'orangeLow')" formControlName="orangeLow" [attr.disabled]="canManageAlertValues == false ? '' : null">
                    </div>
                    <div class="alert-settings-row-input">
                        <input class="form-control" type="number" (change)="changeData(i, 'yellowLow')" formControlName="yellowLow" [attr.disabled]="canManageAlertValues == false ? '' : null">
                    </div>
                    <div class="alert-settings-row-input" [ngClass]="{'oxygen': spoScale == 2 && item.value.measureParamID == 10}">
                        <span class="oxygen" translate>oxygen</span>
                        <input class="form-control" type="number" (change)="changeData(i, 'yellowHigh')" [ngClass]="{'no-oxygen': oxygen == false}"
                         formControlName="yellowHigh" [attr.disabled]="canManageAlertValues == false ? '' : null">
                    </div>
                    <div class="alert-settings-row-input" [ngClass]="{'oxygen': spoScale == 2 && item.value.measureParamID == 10}">
                        <span class="oxygen" translate>oxygen</span>
                        <input class="form-control" type="number" (change)="changeData(i, 'orangeHigh')" [ngClass]="{'no-oxygen': oxygen == false}"
                        formControlName="orangeHigh" [attr.disabled]="canManageAlertValues == false ? '' : null">
                    </div>
                    <div class="alert-settings-row-input" [ngClass]="{'oxygen': spoScale == 2 && item.value.measureParamID == 10}">
                        <span class="oxygen" translate>oxygen</span>
                        <input class="form-control" type="number" (change)="changeData(i, 'redHigh')" [ngClass]="{'no-oxygen': oxygen == false}"
                        formControlName="redHigh" [attr.disabled]="canManageAlertValues == false ? '' : null">
                    </div>
                     <!-- 
                   <input checked="" id="alert-inside-switch-1" type="checkbox" class="alert-bell-switch" autocomplete="off">
                    <label for="alert-inside-switch-1" class="alert-settings-row-switch alert-bell-on">
                        <img src="assets/images/screens/screen-detail/Component 200 – 1.svg">
                    </label>
                    <label for="alert-inside-switch-1" class="alert-settings-row-switch alert-bell-off">
                        <img src="assets/images/screens/screen-detail/Group 2001-red.svg">
                    </label>
                    <div class="alert-settings-row-period">
                        За период
                        <select name="">
                            <option>10 мин.</option>
                            <option>1 час</option>
                            <option>Постоянно</option>
                        </select>
                    </div>  -->
                </div>
                <div 
                class="at-least-error" 
                *ngIf="item.controls['yellowHigh'].hasError('atleast') || item.controls['yellowLow'].hasError('atleast')"
                    >Please fill "Yellow (low)" or "Yellow (high)"</div>
            </div>
            </div>
            <div class="alert-settings-actions">
                <label class="alert-settings-cancel" (click)="close();" translate>cancel</label>
                <button type="button" (click)="onSubmit()" class="alert-settings-save"><label translate>ok</label></button>
            </div>
        </div>
    </div>
<!-- <label class="alert-settings-main-switch">
    <span class="alert-settings-count">{{alertsCount}}</span>
    <input name="" checked type="checkbox" class="alert-bell-switch" autocomplete="off" (click)="changeGlobalAlarmSound(alertAlarmGlobal.checked)" #alertAlarmGlobal>
    <img class="alert-bell-on" src="assets/images/screens/screen-detail/Component 200 – 1.svg">
    <img class="alert-bell-off" src="assets/images/screens/screen-detail/Group 2001-red.svg">
</label> -->
<!--alert settings end-->