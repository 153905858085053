import { ViewEncapsulation } from '@angular/core';
/* eslint-disable no-var */
import { RightsService } from './../../../../../services/rights.service';
import { ApiService } from './../../../../../services/api.service';
import { VitalParamsService } from './../../../../../services/vital-params.service';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
@Component({
  selector: 'app-alert-settings',
  templateUrl: './alert-settings.component.html',
  styleUrls: ['./alert-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AlertSettingsComponent implements OnInit {

  @Output() globalAlarm = new EventEmitter<boolean>();
  @Output() closeAlertSettings = new EventEmitter<boolean>();
  @Input() alertsCount = 0;
  @Input() showAlertChildSettings = false;
  @Input() visitID: number = null;
  @Input() medicalCenterID: number = null;
  public form: FormGroup;
  public canManageAlertValues = false;
  public items: FormArray;
  public vitalParams;
  public vitalParamsTresholds = [];
  public oxygen = false;
  public spoScale;
  constructor(
    private rights: RightsService,
    private apiService: ApiService,
    private vitalParamsService: VitalParamsService,
    private chdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.canManageAlertValues = (this.medicalCenterID)? this.rights.can('change-medical-center-alert-settings') : this.rights.can('permission-write-vital-params-treshold');
    this.vitalParamsService.getVitalParams().subscribe(data => {
      this.vitalParams = data;
    });
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
    this.getAlertSettings();
  }

  private comparisonValidatorAtLeast(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls['yellowHigh'];
      const control2 = group.controls['yellowLow'];
      if (!control1.value &&  !control2.value) {
        control1.setErrors({ atleast: true });
        control2.setErrors({ atleast: true });
      } else {
        if (control1.hasError('atleast')) {
          delete control1.errors['atleast'];
        }
        if (control2.hasError('atleast')) {
          delete control2.errors['atleast'];
        }
      } 
      return;
    };
  }

  private comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {

      const controls = ['redLow', 'orangeLow', 'yellowLow', 'yellowHigh', 'orangeHigh', 'redHigh'];

      for (var i = 0; i < controls.length; i++) {
        const control = group.controls[controls[i]];
        if (control.hasError('compare')) {
          delete control.errors['compare'];
        }
        control.setErrors(null);
        if (control.value) {
          //get next value;
          for(var p = i - 1; p >= 0; p -- ) {
            if (group.controls[controls[p]].value) {
              if (Number(control.value) <= Number(group.controls[controls[p]].value)) {
                control.setErrors({ compare: true });
              }
              break;
            }
          }
          //get previous value
          for(var n = i + 1; n < controls.length; n++ ) {
            if (group.controls[controls[n]].value) {
              if (Number(control.value) >= Number(group.controls[controls[n]].value)) {
                control.setErrors({ compare: true });
              }
              break;
            }
          }
        }
      }
      return;
    };
  }

  public changeGlobalAlarmSound(alarm: boolean) {
    this.globalAlarm.next(alarm);
  }

  private getAlertSettings() {
    var callService = null;
    if (this.medicalCenterID) {
      callService = this.apiService.getVitalPramsTreshHoldByMedCenter(this.medicalCenterID);
    } else {
      callService = this.apiService.getVitalPramsTreshHold(this.visitID);
    }
    callService.subscribe( (data: any) => {
      if (data.success == true) {
        data.data.tresholds.map((x: any) => {
          x.name = this.vitalParams.find(y => y.MeasureParamID == x.VitalParamsTresholdMeasureParamID).MeasureParamValue;
        });
        this.vitalParamsTresholds = data.data.tresholds;
        this.oxygen = data.data.oxygen ? true : false;
        this.spoScale = data.data.spoScale;
        this.setCurrentItems();
        this.chdRef.detectChanges();
      }
    });
  }

  setCurrentItems() {
    this.items = this.form.get('items') as FormArray;
    this.items.clear();
    if (this.vitalParamsTresholds) {
      this.vitalParamsTresholds.forEach(element => {
        const item = {
          name: element.name,
          id: element.VitalParamsTresholdID,
          parentID: element.VitalParamsTresholdParentID ? element.VitalParamsTresholdParentID : element.VitalParamsTresholdID,
          measureParamID: element.VitalParamsTresholdMeasureParamID,
          redLow: element.VitalParamsTresholdRedLow,
          orangeLow: element.VitalParamsTresholdOrangeLow,
          yellowLow: element.VitalParamsTresholdYellowLow,
          yellowHigh: element.VitalParamsTresholdYellowHigh,
          orangeHigh: element.VitalParamsTresholdOrangeHigh,
          redHigh: element.VitalParamsTresholdRedHigh,
        } as any;
        if (this.visitID) {
          item.visitID = element.VitalParamsTresholdVisitID;
        } else {
          item.medicalCenterID = element.VitalParamsTresholdMedicalCenterID;
        }
        this.items.push(this.createItem(item));
      });
    }

  }

  private createItem(data): FormGroup {
    //const index = this.items.controls.length;
    const item = {
      name: [data.name],
      id: data.id,
      parentID: data.parentID,
      measureParamID: [data.measureParamID],
      redLow: [data.redLow],
      orangeLow: [data.orangeLow],
      yellowLow: [data.yellowLow],
      yellowHigh: [data.yellowHigh],
      orangeHigh: [data.orangeHigh],
      redHigh: [data.redHigh],
    } as any;
    if (this.visitID) {
      item.visitID = data.visitID;
    } else {
      item.medicalCenterID = data.medicalCenterID;
    }
    const group = this.formBuilder.group(item);
    group.setValidators([this.comparisonValidator(), this.comparisonValidatorAtLeast()]);
    return  group;
  }

  public changeData(i, type) {
    const value = this.items.controls[i].value[type];
    var item = {
      [type]: value ? parseFloat(this.items.controls[i].value[type]).toFixed(2) : null
    } as any;
    if (this.visitID) {
      item.visitID = this.visitID;
    } else {
      item.medicalCenterID = this.medicalCenterID;
    }
    this.items.controls[i].patchValue(item);
  }

  public onSubmit() {
    if (this.form.valid) {
      var data = [];
      this.items.value.forEach(element => {
        if (element.visitID || element.medicalCenterID) {
          const item = {
            VitalParamsTresholdMeasureParamID: element.measureParamID,
            VitalParamsTresholdParentID: element.parentID,
            VitalParamsTresholdRedLow: element.redLow,
            VitalParamsTresholdOrangeLow: element.orangeLow,
            VitalParamsTresholdYellowLow: element.yellowLow,
            VitalParamsTresholdYellowHigh: element.yellowHigh,
            VitalParamsTresholdOrangeHigh: element.orangeHigh,
            VitalParamsTresholdRedHigh: element.redHigh,
          } as any;
          if (item.visitID) {
            item.VitalParamsTresholdVisitID = element.visitID;
          } else {
            item.VitalParamsTresholdMedicalCenterID = element.medicalCenterID;
          }
          data.push(item);
        }
      });
      if (data.length) {
        var callApi = null;
        if (this.visitID) {
          callApi = this.apiService.saveVitalPramsTreshHold(this.visitID, data);
        } else {
          callApi = this.apiService.saveVitalPramsTreshHoldByMedCenter(this.medicalCenterID, data);
        }
        callApi.subscribe((resp: any) => {
          if (resp.success == true ) {
            this.close();
          } else {
            alert('Can\'t save alert settings');
          }
        });
      } else {
        this.close();
      }
    }
    
  }

  close() {
    this.closeAlertSettings.next();
  }
}
