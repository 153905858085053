import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { NgChatVolumeService } from './ng-chat-volume.service';
@Component({
  selector: 'app-ng-chat-volume',
  templateUrl: './ng-chat-volume.component.html',
  styleUrls: ['./ng-chat-volume.component.scss']
})
export class NgChatVolumeComponent implements OnInit {

  public volumeSlider = false;
  @LocalStorage() chatVolume;
  @Output()
  public onNewMessageSoundPlay: EventEmitter<any> = new EventEmitter();

  constructor(
    private volumeService: NgChatVolumeService
  ) { }

  ngOnInit(): void {
  }

  showVolumeSlider(event) {
    event.stopPropagation();
    this.volumeSlider = !this.volumeSlider;
  }


  changeVolume(event) {
    const value = event.target.value;
    event.stopPropagation();
    this.chatVolume =  value;
    this.volumeService.eventEmitter.emit(value);
  }

}
