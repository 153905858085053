<div class="login-wrap">
  <img class="login-top-image" src="assets/images/logo.svg">
  <img class="login-slogan" src="assets/images/login-saving-lives.png">
  <form [formGroup]="resetForm" class="login-form"  (ngSubmit)="onSubmit(resetForm.value)" *ngIf="checkResetToken">
    <div class="reset-password-title" translate>Reset password</div>
    <div class="login-input-wrap login-text password"
      [ngClass]="{ 'has-error': f.NewPassword.errors && (submitted || f.NewPassword.touched), 'margin-0': f.NewPassword.errors}">
      <input class="login-input" type="password" formControlName="NewPassword" placeholder="Password">
      <!-- <span class="error" *ngIf="f.NewPassword.errors && (submitted || f.NewPassword.touched)">
        Passwords should be minimum 6 characters
      </span> -->
    </div>
    <br/>
    <app-pasword-strength *ngIf="f.NewPassword.errors" [password]="f.NewPassword.value"></app-pasword-strength>
    <div class="login-input-wrap login-text"
      [ngClass]="{ 'has-error': (f.PasswordRepeat.errors && (submitted || f.PasswordRepeat.touched)) || resetForm.errors?.doesMatchPassword }">

      <input  class="login-input" type="password" formControlName="PasswordRepeat" placeholder="Repeat password">
      <div class="login-error" *ngIf="!f.PasswordRepeat.errors?.required && resetForm.errors?.doesMatchPassword && !f.NewPassword.errors && (submitted || f.PasswordRepeat.touched)"
      translate>
        Password does not match
      </div>
      <div class="login-error"  *ngIf="f.PasswordRepeat.errors?.required && !f.NewPassword.errors && (submitted || f.PasswordRepeat.touched)"
        translate>
        Repeat password is required
      </div>
    </div>
    <br/>
    <div class="login-input-wrap login-button">
      <button *ngIf="!submitted" class="login-input" [disabled]="loading" translate>Reset</button>
    </div>
  </form>
  <ng-template [ngIf]="successfulReset">
    <div class="congratulation-title" translate>Congratulation!</div>
    <div class="submessage-text" translate>
      Your password has been changed successfully! Now you can try to login in with the new password and don't forget it again :)!
    </div>
    <div class="submessage-text" translate>
      <a routerLink="/login" translate>Login</a>
    </div>
    
  </ng-template>
  </div>