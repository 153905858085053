import { MessageType } from './message-type.enum';

export class Message
{
    public type?: MessageType = MessageType.Text;
    public fromFirstName: any;
    public fromLastName: any;
    public fromId: any;
    public fromMedCenterID: any;
    public toId: any;
    public toVisitID?: any;
    public message: string;
    public dateSent?: Date;
    public dateSeen?: Date;
    public _id?: any;
    public seen?: any;
    public seenBy?: any[];
    public userID?: Number[];
}
