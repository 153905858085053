import { BaseLayoutComponent } from './../../layout/base-layout/base-layout.component';
import { Component, OnInit, Injector } from '@angular/core';

@Component({
  selector: 'app-chat-full-screent',
  templateUrl: './chat-full-screent.component.html',
  styleUrls: ['./chat-full-screent.component.scss']
})
export class ChatFullScreentComponent extends BaseLayoutComponent implements OnInit {

  constructor(
    private injector: Injector
  ) { 
    super(injector)
  }

  ngOnInit(): void {
  }

}
