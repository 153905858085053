import { NgChatModule } from './components/ng-chat/ng-chat.module';
import { ForgotPasswordComponent } from './routes/users/forgot-password/forgot-password.component';
import { Observable } from 'rxjs';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { LoginComponent } from './routes/login/login.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { HeadingService } from './services/heading.service';
import { InitResolver } from './resolvers/init.resolver';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';  
import { registerLocaleData } from '@angular/common';
import localeBg from '@angular/common/locales/bg';
import localeSl from '@angular/common/locales/sl';
import localeNl from '@angular/common/locales/nl';
import localeSr from '@angular/common/locales/sr';
import localeSv from '@angular/common/locales/sv';
import { VerifyEmailComponent } from './routes/verify-email/verify-email.component';
import { AlertSettingsModule } from './routes/screen/components/alerts/alert-settings/alert-settings.module';
import { VisitDataService } from './services/visit-data.service';
import { ChatFullScreentComponent } from './routes/chat-full-screent/chat-full-screent.component';
import { PatientResolver } from './resolvers/patient.resolver';
import { MatIconModule } from '@angular/material/icon';

registerLocaleData(localeBg);
registerLocaleData(localeSl);
registerLocaleData(localeNl);
registerLocaleData(localeSr);
registerLocaleData(localeSv);
export class CustomLoader implements TranslateLoader {

  constructor(private http: HttpClient) { }

  public getTranslation(lang: string): Observable<any> {
    return this.http.get(environment.protocol + environment.apiUrl + '/v1/translate/' + lang).map(
      (res: any) => {
        return res.data;
      }
    );
  }
}

@NgModule({
  declarations: [
    LayoutComponent,
    VerifyEmailComponent,
    DashboardComponent,
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    KeepHtmlPipe,
    ChatFullScreentComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxWebstorageModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    NgChatModule,
    FormsModule,
    AlertSettingsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    HeadingService,
    VisitDataService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptchaV2.siteKey,
      } as RecaptchaSettings,
    },
    InitResolver,
    PatientResolver,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      deps: [AuthService],      //some service handling global settings
      useFactory: (authService) => authService.getLanguage()  //returns locale string
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
        private translate: TranslateService,
        private localStorage: LocalStorageService) {
    const presetLanguage: string = this.localStorage.retrieve('currentLang') || 'en';
    this.translate.setDefaultLang(presetLanguage);
    this.translate.use(presetLanguage);
    this.localStorage.store('currentLang', presetLanguage);
  }
}
