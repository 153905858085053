<div class="chat-container">
    <ng-chat *ngIf="showChat && medicalCenters.length" 
    [adapter]="adapter"
    [userId]="user.UserID"
    [userFirstName]="user.UserFirstName" 
    [userLastName]="user.UserLastName" 
    [participantMedicalCenterID]="patientMedicalCenter"
    [medicalCenters]="medicalCenters" 
    [isCollapsed]="false">
</ng-chat>
</div>
