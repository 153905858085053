import { mixinMobileApp } from 'src/app/mixins/mobile-app';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, Renderer2, OnDestroy, HostListener, ViewChild, ElementRef, ViewEncapsulation, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseLayoutComponent } from 'src/app/layout/base-layout/base-layout.component';
import { VisitDataService } from 'src/app/services/visit-data.service';
const MobileAppMixin = mixinMobileApp(BaseLayoutComponent);
@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent extends MobileAppMixin implements OnInit, OnDestroy {
    @ViewChild('showScreen') private checkInput: ElementRef<HTMLInputElement>;
    @HostListener('window:popstate', ['$event'])
    @HostListener('window:keydown.escape')
    dismiss() {
        if (this.checkInput.nativeElement.checked) {
            this.closeSideBar();
        }
    }
    public currPatientID: number = 0;
    public patientMedicalCenter: number = 0;
    public customPatientScreens: any = [];
    private subscriptions: Array<Subscription> = [];
    private liveReloadLoaded = false;
    public chatContainerClass = '';

    constructor(
        private renderer: Renderer2,
        private route: Router,
        private injector: Injector,
        private visitDataService: VisitDataService
        //private advPatientViewService: AdvancedPatientViewService,
    ) {
        super(injector);

        if (!this.mobileAppObj) {
            document.querySelectorAll("head > style")[0].remove();
        }

        //set chat-container class
        if (this.route.url.startsWith("/screen/icu-patient")) {
            this.chatContainerClass = 'icu-patient';
        } else if (this.route.url.startsWith("/screen/nightingale-ro")) {
            this.chatContainerClass = 'nightingale-ro';
        } else if (this.route.url.startsWith("/screen/alerts") || this.route.url.startsWith("/screen/patient-alerts")) {
            this.chatContainerClass = 'alerts';
        } else if (this.route.url.startsWith("/screen/history")) {
            this.chatContainerClass = 'history';
        }

        this.renderer.addClass(document.body, 'screen-detals');
        this.subscriptions.push(route.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (this.route.url.substring(0, 15) == "/screen/history") {
                    this.renderer.addClass(document.body, 'history');
                    this.renderer.addClass(document.body, 'border-box');
                } else {
                    this.renderer.removeClass(document.body, 'history');
                    this.renderer.removeClass(document.body, 'border-box');
                }
                this.setPatientScreens();
            }
        }));
    }

    ngOnInit(): void {
        if (!this.liveReloadLoaded && environment.liveReload) {
            this.liveReloadLoaded = true;
            const script = window.document.createElement('script');
            script.src = 'http://localhost:35729/livereload.js?snipver=1';
            window.document.head.appendChild(script);
        }
        if (this.showChat && this.currPatientID) {
            this.subscriptions.push(this.visitDataService.getVisitData().subscribe(data => {
                this.patientMedicalCenter = data.VisitMedicalCenterID;
            }));   
        }
    }

    public mergeRouteParams(router: Router): { [key: string]: string } {
        let params = {};
        let route = router.routerState.snapshot.root;
        do {
            params = { ...params, ...route.params };
            route = route.firstChild;
        } while (route);

        return params;
    }

    private setPatientScreens() {
        const mergedParams = this.mergeRouteParams(this.route);
        this.currPatientID = Number(mergedParams['id']);
        if (this.currPatientID) {
            this.setCustomPatientScreens();
        }
    }

    private setCustomPatientScreens() {
        //this.customPatientScreens = this.advPatientViewService.getUserDashBoards().map(db => { return { id: db.id, name: db.name } });
    }

    ngOnDestroy() {
        this.renderer.removeClass(document.body, 'screen-detals');
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        });
    }

    public deleteCustomScreen(dashboard: any) {
        if (confirm('Are you sure you want to delete: ' + dashboard.name)){
            this.advPatientViewService.deleteDashboard(dashboard.id);
            this.setCustomPatientScreens();
        }
    }

    closeSideBar() {
        this.checkInput.nativeElement.checked = false;
        // console.log(this.showScreen);
        // this.showScreen.checked = true;
    }
    openSideBar() {
        this.checkInput.nativeElement.checked = true;
        // console.log(this.showScreen);
        // this.showScreen.checked = true;
    }
}
