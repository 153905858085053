import { AfterViewInit, Directive, ElementRef, EventEmitter, Host, Input, OnChanges, OnDestroy, Output, SimpleChange } from "@angular/core";

@Directive({
  selector: "[enterTheViewportNotifier]"
})
export class EnterTheViewportNotifierDirective implements AfterViewInit, OnDestroy {
  @Output() visibilityChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() enterTheViewportNotifier = ''; 
  @Input() enable = false; 
  @Input() message = '';
  private _observer: IntersectionObserver;

  constructor(@Host() private _elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    if (!this.enable) {
      return;
    }
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.0
    };
    this._observer = new IntersectionObserver(this._callback, options);
    this._observer.observe(this._elementRef.nativeElement);
  }

  ngOnChanges(changes) {
    if (changes.enable?.currentValue === true && changes.enable?.previousValue === false ) {
      if (!this._observer) {
        this.ngAfterViewInit();
      }
    } else if (changes.enable?.currentValue === false && changes.enable?.previousValue === true ) {
      this.ngOnDestroy();
    }
  }

  ngOnDestroy() {
    if (this._observer) {
      this._observer.disconnect();
      this._observer = null;
    }
  }

  private _callback = (entries, observer) => {
    entries.forEach(entry => {
      this.visibilityChange.emit(entry.isIntersecting ? 'VISIBLE' : 'HIDDEN')
    });
  };
}
