import { mixinMobileApp } from 'src/app/mixins/mobile-app';
/* eslint-disable no-var */
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from './services/ngb-date-fr-parser-formatter';
import { Title } from '@angular/platform-browser';
import { Component, OnDestroy, HostListener } from '@angular/core';
import { HeadingService } from './services/heading.service';
import { ApiService } from './services/api.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class AppComponent extends mixinMobileApp(class {}) implements OnDestroy{

  @HostListener('window:sendNewToken', ['$event'])
  setSendNewToken(event: CustomEventInit) {
    var data = {
      newToken: event.detail.newToken,
      oldToken: event.detail.oldToken,
      userID: event.detail.userID,
      isAndroid: 1
    };
    this.apiService.saveNewToken(data).subscribe((resp: any) => {
      if (resp.success == true) {
        console.log('successfullsend token');
        if (this.mobileAppObj) {
          this.localStorage.store('firebaseToken', event.detail.newToken);
        }
      }
    });
  }
  @HostListener('window:removeFirebaseToken', ['$event'])
  setRemoveFirebaseToken(event: CustomEventInit) {
    const firebaseToken = event.detail.firebaseToken;
    if (firebaseToken) {
      this.api$.deleteFirebaseToken({token: firebaseToken}).subscribe((resp: any) => {
        if (resp.success == true) {
          console.log('delete firebase token');
        }
      });
    }
  }
  public title = 'app';
  public subscriptions: Array<Subscription> = [];

  constructor(
    private heading$: HeadingService,
    private title$: Title,
    private localStorage: LocalStorageService,
    private router: Router,
    private apiService: ApiService
  ) {
    super();
    console.log('app component');
    this.subscriptions.push(this.heading$.title.subscribe((t) => {
      this.title$.setTitle(this.heading$.getTitleTranslatedText(t).replace(/<[^>]*>/g, ''));
    }));

    this.subscriptions.push(this.localStorage.observe('token').subscribe( token => {
      if (!token) {
        if (this.mobileAppObj) {
          this.router.navigate(['/screen/nurses-login']);
        } else {
          location.href = '/login';
        }
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
