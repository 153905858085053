<div class="login-wrap">
  <img class="login-top-image" src="assets/images/logo.svg">
  <div class="login-form">
      <img class="login-slogan" src="assets/images/login-saving-lives.png">
      <div class="success" *ngIf="success">
        <div class="success-title" translate>Congratulation!</div>
        <div class="success-message" translate>Your registration has been confirmed! Now you can log in:)!</div>
        <div class="success-login"><a routerLink="/login" translate>Login</a></div>
      </div>
      <div class="error" *ngIf="!success && error">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{error | translate}}
      </div>
  </div>
</div>
<img class="login-bottom-image" src="assets/images/powered-by.svg">