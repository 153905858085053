/* eslint-disable no-var */
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pasword-strength',
  templateUrl: './pasword-strength.component.html',
  styleUrls: ['./pasword-strength.component.scss']
})
export class PaswordStrengthComponent implements OnInit, OnChanges {

  @Input() password;
  color = '';

  min = false;
  small = false;
  capital = false;
  digit = false;
  special = false;
  arrTest = [];
  percentage = 0;

  minReg = /^.{8,}$/;
  smallReg = /[a-z]/;
  capitalReg = /[A-Z]/;
  digitReg = /\d+/;
  specialReg = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.arrTest = [];
    const password = changes.password.currentValue;
    this.min = this.minReg.test(password);
    this.small = this.smallReg.test(password);
    this.capital = this.capitalReg.test(password);
    this.digit = this.digitReg.test(password);
    this.special = this.specialReg.test(password);

    this.percentage = 0;
    if (this.min) {
      this.percentage += 20;
    }
    if (this.small) {
      this.percentage += 20;
    }
    if (this.capital) {
      this.percentage += 20;
    }
    if (this.digit) {
      this.percentage += 20;
    }
    if (this.special) {
      this.percentage += 20;
    }
    if (this.percentage > 0) {
      if (this.percentage <= 20) {
        this.color = 'background-red';
      } else if (this.percentage <= 40) {
        this.color = 'background-orange';
      } else if (this.percentage <= 60) {
        this.color = 'background-yellow';
      } else if (this.percentage <= 80) {
        this.color = 'background-white-green';
      } else {
        this.color = 'background-green';
      }
    }

  }

}
